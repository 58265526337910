import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Badge,
  DropdownItem,
  Row,
  Col,
  Table,
} from "reactstrap"
import companies from "assets/images/companies"

import { Link } from "react-router-dom"
import moment from "moment"

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"

const OrderRow = props => {
  const [modal, setmodal] = useState(false)
  const [subscribemodal, setSubscribemodal] = useState(false)

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
  }))

  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(true)
    }, 2000)
  }, [])

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch])

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y, h:mm a")
    return date1
  }

  const handleProjectClick = arg => {
    const project = arg

    setProjectList({
      id: project.id,
      img: project.img,
      name: project.name,
      description: project.description,
      status: project.status,
      color: project.color,
      dueDate: project.dueDate,
      team: project.team,
    })

    setIsEdit(true)

    toggle()
  }

  const handleDeleteProject = project => {
    dispatch(onDeleteProject(project))
  }

  return (
    <React.Fragment>
      <tr
        className={`tr-border-${props.color} mb-2`}
        onClick={props.onClick}
        style={{ cursor: "pointer" }}
      >
        {/* <Row lg="12">
          <Col lg="12"> */}
        {/* <Row>
              <Col lg="12">
                <h5 className="text-truncate font-size-14">
                  <Link
                    className="text-dark"
                  >
                    Order Number : {props.order_id}
                  </Link>
                </h5>
              </Col>
            </Row> */}

        <Row lg="12">
          <Col lg="6">
            <Row>
              <h5 className="text-truncate">
                <Link
                  className="text-dark cursor-text"
                  onClick={e => e.stopPropagation()}
                >
                  Order Number : {props.order_id}
                </Link>
              </h5>
            </Row>
            <Row>
              <Col lg="6">
                <p className="text-muted mb-1">
                  Number of items : {props.total_items}
                </p>
                <p
                  className="text-muted mb-1 cursor-text"
                  onClick={e => e.stopPropagation()}
                >
                  Amount : {props.order_total} | {props.payment_mode}
                  {/* Amount :{" "}
                  <span>
                    {props?.total_cod_charges ?
                      (props?.order_total - props?.total_cod_charges) : props?.order_total }{" "}
                    {props.payment_mode == "COD" && props?.total_cod_charges ?
                      `+ ${props?.total_cod_charges}` : ""}{" "}
                    | {props?.payment_mode}
                  </span> */}
                </p>
                <p
                  className="text-muted mb-1 cursor-text"
                  onClick={e => e.stopPropagation()}
                >
                  Promo Discount : {props?.promo_discount}{" "}
                  {props?.promocode && <span>| {props?.promocode}</span>}
                </p>
                {/* <p className="text-muted mb-1">
                    Payment Mode : {props.payment_mode}
                  </p> */}
                {/* <p className="text-muted mb-1">
                    No of Sellers : {props.no_of_sellers}
                  </p> */}
                <p className="text-muted mb-1">
                  Date & Time : {handleValidDate(props.created_at.$date)}
                </p>
              </Col>
              {/* <Col lg="4">
                <td>
                  <p className="text-muted mb-1">
                    Amount : {props.order_total}
                  </p>
                  <p className="text-muted mb-1">
                    Payment Mode : {props.payment_mode}
                  </p>
                  <p className="text-muted mb-1">
                    Promo Discount : {props.promo_discount}
                  </p>
                </td>
              </Col> */}
              <Col lg="6">
                <div
                  className="text-muted mb-1 cursor-text"
                  style={{ whiteSpace: "pre-wrap" }}
                  onClick={e => e.stopPropagation()}
                >
                  Buyer Name : {props.billing_customer_name}
                </div>
                <div
                  className="text-muted mb-1 cursor-text"
                  onClick={e => e.stopPropagation()}
                >
                  Buyer City : {props.billing_city}
                </div>
                <div
                  className="text-muted mb-1 cursor-text "
                  onClick={e => e.stopPropagation()}
                >
                  Buyer Contact : {props.billing_phone}
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg="6" className="align-self-center">
            <Row>
              <Col lg="8">
                {props?.product_details &&
                  props?.product_details.map((item, index) => (
                    <div className="d-flex align-content-lg-start">
                      <Col>
                        <p
                          className="text-muted font-bold mb-1 cursor-text"
                          style={{ whiteSpace: "pre-wrap" }}
                          onClick={e => e.stopPropagation()}
                        >
                          {/* Product Name :  */}
                          {item.prod_name}
                        </p>
                        <p
                          className="text-muted mb-1 cursor-text"
                          style={{ whiteSpace: "pre-wrap" }}
                          onClick={e => e.stopPropagation()}
                        >
                          {/* Brand | Seller :   */}
                          {item.brand} | {item.vendor_name}
                        </p>
                        <p
                          className="text-muted mb-1 cursor-text"
                          style={{ whiteSpace: "pre-wrap" }}
                          onClick={e => e.stopPropagation()}
                        >
                          {/* Vehicle :  */}
                          {item.make} {item.model} {item.year}
                        </p>
                      </Col>
                      <div className="d-flex justify-content-center align-items-center">
                        <div
                          // className={`text-muted mb-1 p-2 bordered-div w-100 tr-border-1 tr-border-${props.color}`}
                          className={`ms-3 p-1 bordered-div w-100 text-capitalize cursor-pointer
                            ${
                              item.order_status == "New Order"
                                ? "glowbutton"
                                : "text-muted"
                            }`}
                          style={
                            item.order_status == "DELIVERED" ||
                            item.order_status == "RTO DELIVERED"
                              ? { border: "2px solid green" }
                              : { border: "0.5px solid black" }
                          }
                        >
                          {item.order_status.toLowerCase()}
                        </div>
                      </div>
                    </div>
                  ))}
              </Col>
              <Col
                lg="3"
                className="d-flex justify-content-center align-items-center"
                // style={{cursor:'pointer'}}
              >
                <button
                  className={`text-muted bg-white p-2 bordered-div w-100 tr-border-1 tr-border-${props.color}`}
                >
                  {props.status}
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </tr>
    </React.Fragment>
  )
}

OrderRow.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(OrderRow)
