import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";


const useLastScroll = dom => {
  const  history  = useHistory()
  const [scrollInfos, setScrollInfos] = useState(() =>
    localStorage.getItem("scroll_pos")
  );
  const scrollTop = scrollInfos || 0;


  const scrollMove = () => {
    if (!scrollInfos && scrollInfos !== 0) {
      return;
    }

    localStorage.setItem("scroll_pos", 0);
    setScrollInfos(localStorage.getItem("scroll_pos"));
    window.scrollTo(0, scrollTop);
  };

  const scrollOnceMove = useCallback(scrollMove, [scrollInfos]);

  const scrollSave = () => {
    const pos = dom ? dom.scrollTop : window.scrollY;
    return localStorage.setItem("scroll_pos", pos);
  };

  // react router 
  // history.goBack() 
  useEffect(() => {
    const listen = history.listen(scrollSave);
    return () => {
      
      listen();
    };
  });

  return { scrollMove, scrollOnceMove, scrollSave };
};

export default useLastScroll;