import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
} from "reactstrap"

import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

//redux

const OrderCardLarge = props => {
  const history = useHistory()

  return (
    <React.Fragment>
      <Col className={"col-md-12 "}>
        <Card
          className=""
          style={props.bg}
          onClick={() => history.push(props.url)}
        >
          <CardBody className={`${props.large ? "py-4" : "py-3"} px-2`}>
            <p
              className={`fw-medium text-center ${
                props.large ? "font-size-18" : "font-size-16"
              }`}
            >
              {props.title} {"- " + props.number}
            </p>
            <h6 className={`text-center font-size-14 ${props.large ? "" : ""}`}>
              {props.percent}
            </h6>
            <h6
              className={`mb-0 text-center ${
                props.large ? "font-size-18" : "font-size-16"
              }`}
            >
              {props.amount}
            </h6>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

OrderCardLarge.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(OrderCardLarge)
