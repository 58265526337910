import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  Button,
} from "reactstrap"
import classnames from "classnames"
import { isEmpty } from "lodash"
import "nouislider/distribute/nouislider.css"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
//redux
import { get, post } from "../../helpers/api_helper"
import { EditOutlined, DeleteOutlined } from "@ant-design/icons"
import { success, error, Loader } from "../../service/alerts"

const VehiclesModel = props => {
  const { history } = props
  const [productList, setProductList] = useState([])
  const [models, setModels] = useState([])
  const [activeTab, setActiveTab] = useState("1")
  // eslint-disable-next-line no-unused-vars

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  const [products, setProducts] = useState([])
  const {
    match: { params },
  } = props
  const getProducts = async () => {
    const result = await get(`/api/v2/vehiclemake/?id=${params.id}`)
    if (result.data) {
      setProductList(result.data)
    } else {
      setProductList([])
    }
    const results = await get(`/api/v2/vehiclemodel/?id=${params.id}`)
    if (results.data) {
      setModels(results.data)
    } else {
      setModels([])
    }
  }
  useEffect(() => {
    getProducts()
  }, [])
  const handlePageClick = page => {
    setPage(page)
  }
  const deleteMake = id => {
    post("/api/v2/delete_make/", { id: id })
      .then(res => {
        success("able to delete")
        getProducts()
      })
      .catch(err => {
        error("sorry not able delete")
      })
  }
  const onSearch = async searchValue => {
    let url = "/api/v2/search/?q=model&active=true&make_id=" + params.id
    if (searchValue) {
      url = `${url}&s=${searchValue}`
    }
    get(url)
      .then(res => {
        setModels(res.data)
      })
      .catch(err => {
        setModels([])
      })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Make | BrakeUp</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Vehicles" breadcrumbItem="Make" />
          <Row>
            <Col lg="12">
              <Row className="mb-3">
                <Col lg="8" sm="6">
                  <Form className="mt-4 mt-sm-0 float-sm-end d-flex align-items-center">
                    <div className="search-box me-2">
                      <div className="position-relative">
                        <Input
                          type="text"
                          className="form-control border-0"
                          placeholder="Search By Make/Model"
                          onChange={e => onSearch(e.target.value)}
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Form>
                </Col>
              </Row>
              <Row>
                {!isEmpty(productList) &&
                  productList.map((product, key) => (
                    <Col xl="12" sm="12" key={"_col_" + key}>
                      <Card>
                        <CardBody>
                          <Row>
                            <Col xl="3" sm="4">
                              <div className="product-img position-relative">
                                <img
                                  src={
                                    product.image
                                      ? product.image[0].startsWith("https://")
                                        ? product.image[0]
                                        : `https://${product.image[0]}`
                                      : ""
                                  }
                                  alt=""
                                  className="img-fluid mx-auto d-block"
                                  style={{ height: "150px" }}
                                />
                              </div>
                            </Col>
                            <Col xl="9" sm="8">
                              <div className="mt-4 ">
                                <h5 className="mb-3 text-truncate">
                                  MAKE NAME: {product.make}
                                </h5>

                                <span className="text-muted me-2">
                                  Total Models: {product.total_models}
                                </span>
                                <br></br>
                                <span className="text-muted me-2">
                                  Total Products: {product.total_products_make}
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
              </Row>
              <Row>
                {!isEmpty(models) &&
                  models.map((product, key) => (
                    <Col xl="12" sm="12" key={"_col_" + key}>
                      <Card>
                        <CardBody
                          onClick={() => {
                            history.push(`/make-versions/${product._id.$oid}`)
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <Row>
                            <div>
                              {/* <span className="text-muted me-2" style={{float:"right"}}>
                                SL No: {product.total_models}
                            </span> */}
                            </div>
                            <Col xl="3">
                              <div className="product-img position-relative">
                                <h5 className="mb-3 text-truncate">
                                  <Link className="text-dark">
                                    MODEL NAME: {product.model}
                                  </Link>
                                </h5>
                                <img
                                  src={
                                    product.image
                                      ? product.image[0].startsWith("https://")
                                        ? product.image
                                        : `https://${product.image}`
                                      : ""
                                  }
                                  alt=""
                                  className="img-fluid mx-auto d-block"
                                  style={{ height: "20px" }}
                                />
                              </div>
                            </Col>
                            <Col xl="5" sm="6">
                              <div className="mt-4 ">
                                <span className="text-muted me-2">
                                  Total Versions: {product.total_versions}
                                </span>
                              </div>
                            </Col>
                            <Col xl="4" sm="6">
                              <div className="mt-4 ">
                                <span className="text-muted me-2">
                                  Total Products: {product.total_products_model}
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

VehiclesModel.propTypes = {
  products: PropTypes.array,
  history: PropTypes.object,
  onGetProducts: PropTypes.func,
}

export default withRouter(VehiclesModel)
