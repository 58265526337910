import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
    Container,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    Badge,
    DropdownItem,
    Row,
    Col,
    Table

} from "reactstrap"
import companies from "assets/images/companies"

import { Link } from 'react-router-dom';
import moment from 'moment';

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"




//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"



const BuyerCartDetails = props => {

    const handleDeleteProject = project => {
        dispatch(onDeleteProject(project))
    }

    return (
        <React.Fragment>
            <tr className={`tr-border-yellow mb-2`}
                // onClick={() => props.onClick(props.item)}
                style={{ marginBottom: "10px" }}>
                <Row>
                    <Col lg="12">
                        <Row className="mb-2">
                            <Col lg="12">
                                <h5 className="text-truncate font-size-16">
                                    <Link className="text-dark">
                                        {props.prod_name} -  {props.vehicle_details?.make} {props.vehicle_details?.model} {props.vehicle_details?.version ? props.vehicle_details.version : ""} {props.vehicle_details?.year} {props.vehicle_details?.fueltype}
                                    </Link>
                                </h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="3">
                                <td>
                                    <img className="" style={{
                                        width: '8rem'
                                    }} src={props.image[0]} />
                                </td>
                            </Col>
                            <Col lg="4">
                                <td>
                                    <div className="text-muted mb-1" >
                                        Brand : {props.brand}
                                    </div>
                                    <div className="text-muted mb-1" >
                                        Vendor City : {props.vendor_city}
                                    </div>
                                    <div className="text-muted mb-1" >
                                        Selected Quantity : {props.quantity}
                                    </div>
                                    <div className="text-muted mb-1" >
                                        Stock Status : {props.stock_status}
                                    </div>
                                </td>
                            </Col>
                            <Col lg="4">
                                <td>
                                    <div className="text-muted mb-1" >
                                        MRP : {props.mrp}
                                    </div>
                                    <div className="text-muted mb-1" >
                                        Discount Price : {props.discount_price}
                                    </div>
                                    <div className="text-muted mb-1" >
                                        Discount Percentage : {props.discount_percentage}
                                    </div>
                                    <div className="text-muted mb-1" >
                                        Selling Price : {props.price}
                                    </div>
                                </td>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </tr>
        </React.Fragment>
    )
}

BuyerCartDetails.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
}

export default withTranslation()(BuyerCartDetails)
