import React, { useState, useEffect } from "react"
import { useForm, Controller, useFieldArray } from "react-hook-form"
import { CustomInput, CustomSelect } from "../Ecommerce/customInputs"
import { Button, Label, Row, Col } from "reactstrap"
import { post, get } from "../../helpers/api_helper"

export const AllProducts = props => {
  const {
    handleSubmit,
    errors,
    control,
    setValue,
    watch,
    register,
    unregister,
  } = useForm({
    mode: "onChange",
  })
  const onSubmit = values => {
    values.type = "allproducts"
    values.name = values?.product?.label
    props.productFilerData(values)
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col lg="9">
          <div className="mb-3">
            <Label htmlFor="productname">All Products</Label>
            <Controller
              as={<CustomSelect />}
              defaultValue={null}
              name={"product"}
              options={[{ value: "All_Products", label: "All Products" }]}
              onChange={([e]) => {
                return { value: e }
              }}
              control={control}
              rules={{ required: true }}
            />
            {errors.product && (
              <span style={{ color: "red" }}>Product is required.</span>
            )}
          </div>
        </Col>
        <Col lg="1" style={{ marginTop: "26px" }}>
          <Button color="primary" type="submit" disabled={props.hide}>
            Add
          </Button>
        </Col>
      </Row>
    </form>
  )
}

export const AllBrands = props => {
  const {
    handleSubmit,
    errors,
    control,
    setValue,
    watch,
    register,
    unregister,
  } = useForm({
    mode: "onChange",
  })
  const [brands, setBrands] = useState([])
  useEffect(() => {
    get("/api/v2/products/add_brand/")
      .then(res => {
        let outs = []
        res.data &&
          res.data.map(items => {
            outs.push({ label: items.brand_name, value: items._id["$oid"] })
          })
        setBrands(outs)
      })
      .catch(err => {})
  }, [])
  const onSubmit = values => {
    values.type = "brand"
    values.name = values?.brand?.label
    props.productFilerData(values)
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col lg="9">
          <div className="mb-3">
            <Label htmlFor="productname">Select Brand</Label>
            <Controller
              as={<CustomSelect />}
              defaultValue={null}
              name={"brand"}
              options={brands}
              onChange={([e]) => {
                return { value: e }
              }}
              control={control}
              rules={{ required: true }}
            />
            {errors.brand && (
              <span style={{ color: "red" }}>Brand is required.</span>
            )}
          </div>
        </Col>
        <Col lg="2" style={{ marginTop: "26px" }}>
          <Button color="primary" type="submit" disabled={props.hide}>
            Add
          </Button>
        </Col>
      </Row>
    </form>
  )
}

export const AllCategorys = props => {
  const {
    handleSubmit,
    errors,
    control,
    setValue,
    watch,
    register,
    unregister,
  } = useForm({
    mode: "onChange",
  })
  const [categorys, setCategorys] = useState([])
  useEffect(() => {
    get("/api/v2/products/list_category/")
      .then(res => {
        let outs = []
        res.category &&
          res.category.map(items => {
            outs.push({ label: items, value: items })
          })
        setCategorys(outs)
      })
      .catch(err => {})
  }, [])
  const onSubmit = values => {
    values.type = "category"
    values.name = values?.category?.label
    props.productFilerData(values)
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col lg="9">
          <div className="mb-3">
            <Label htmlFor="productname">Select Category</Label>
            <Controller
              as={<CustomSelect />}
              defaultValue={null}
              name={"category"}
              options={categorys}
              onChange={([e]) => {
                return { value: e }
              }}
              control={control}
              rules={{ required: true }}
            />
            {errors.category && (
              <span style={{ color: "red" }}>Category is required.</span>
            )}
          </div>
        </Col>
        <Col lg="2" style={{ marginTop: "26px" }}>
          <Button color="primary" type="submit" disabled={props.hide}>
            Add
          </Button>
        </Col>
      </Row>
    </form>
  )
}

export const Product = props => {
  const {
    handleSubmit,
    errors,
    control,
    setValue,
    watch,
    register,
    unregister,
  } = useForm({
    mode: "onChange",
  })
  const [brands, setBrands] = useState([])
  const [products, setProducts] = useState([])
  const [brand, setBrand] = useState({})
  const [sellerPRTNo, setsellerPRTNo] = useState([])

  useEffect(() => {
    get("/api/v2/products/add_brand/")
      .then(res => {
        let outs = []
        res.data &&
          res.data.map(items => {
            outs.push({ label: items.brand_name, value: items._id["$oid"] })
          })
        setBrands(outs)
      })
      .catch(err => {})
  }, [])
  const onSubmit = values => {
    console.log(values, "valuesss")
    values.type = "brand_product"
    // values.name = values?.product?.label;
    values.brand_id = brand.value
    values.seller_part_no = values?.sellerPartNo?.label
    values.name = values?.sellerPartNo?.name
    props.productFilerData(values)
  }
  const getProduct = values => {
    setBrand(values)
    get(`/api/v2/promocode/getbrandproducts/?brand_id=${values.value}`)
      .then(res => {
        let outs = []
        res.data &&
          res.data.map(items => {
            outs.push({ label: items.prod_name, value: items._id["$oid"] })
          })
        let prtNo = []
        res.data &&
          res.data.map(items => {
            console.log(res.data, "selller")
            prtNo.push({
              label: items.seller_part_no,
              value: items._id["$oid"],
              name: items.prod_name,
            })
          })
        setProducts(outs)
        setsellerPRTNo(prtNo)
        setValue("product", "")
      })
      .catch(err => {
        setProducts([])
        setValue("product", "")
        setsellerPRTNo([])
      })
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col lg="4">
          <div className="mb-3">
            <Label htmlFor="productname">Select Brand</Label>
            <Controller
              as={<CustomSelect />}
              defaultValue={null}
              name={"brand"}
              options={brands}
              onChange={([e]) => {
                return { value: e }
              }}
              control={control}
              rules={{ required: true }}
              setSelectedPackage={getProduct}
            />
            {errors.brand && (
              <span style={{ color: "red" }}>Brand is required.</span>
            )}
          </div>
        </Col>
        {/* <Col lg="3">
                    <div className="mb-3">
                        <Label htmlFor="productname">Select Product</Label>
                        <Controller
                            as={<CustomSelect />}
                            defaultValue={null}
                            name={"product"}
                            options={products}
                            onChange={([e]) => {
                                return { value: e };
                            }}
                            control={control}
                            rules={{ required: true }}
                        />
                        {errors.product && <span style={{ color: "red" }}>Product is required.</span>}
                    </div>
                </Col> */}
        <Col lg="5">
          <div className="mb-3">
            <Label htmlFor="productname">Select Seller Part No.</Label>
            <Controller
              as={<CustomSelect />}
              defaultValue={null}
              name={"sellerPartNo"}
              options={sellerPRTNo}
              onChange={([e]) => {
                return { value: e }
              }}
              control={control}
              rules={{ required: true }}
            />
            {errors.brand && (
              <span style={{ color: "red" }}>Seller Part No. is required.</span>
            )}
          </div>
        </Col>
        <Col lg="1" style={{ marginTop: "26px" }}>
          <Button color="primary" type="submit" disabled={props.hide}>
            Add
          </Button>
        </Col>
      </Row>
    </form>
  )
}
