import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
  Input,
} from "reactstrap"
import classnames from "classnames"
import { isEmpty } from "lodash"

//Import Star Ratings
import StarRatings from "react-star-ratings"

//Import Product Images
import { productImages } from "assets/images/product"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import actions
import {
  getProductDetail as onGetProductDetail,
  getProductComments,
  onAddReply as onAddReplyAction,
  onAddComment as onAddCommentAction,
} from "store/actions"
import RecentProducts from "./RecentProducts"
import Reviews from "./Reviews"
import { get, post } from "../../../helpers/api_helper"
import { Loader } from "../../../helpers/loader"
//redux
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { error, success } from "toastr"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { ModalPriceDetails } from "./profileCalculator"
import { EditOutlined } from "@ant-design/icons"

const EcommerceProductDetail = props => {
  const dispatch = useDispatch()
  const [product, setProduct] = useState({})
  const [orders, setOrders] = useState({})
  const [isLoader, setIsLoader] = useState(false)
  const [sellerPrice, setSellerPrice] = useState(0)
  const [sellerStock, setSellerStock] = useState(0)
  const [isActive, setIsActive] = useState(false)
  const [isApproved, setIsApproved] = useState(false)
  const [minExtimated, setMinExtimated] = useState(0)

  const history = useHistory()
  const {
    match: { params },
  } = props
  const [activeTab, setActiveTab] = useState(0)
  const getProductDetails = async () => {
    const result = await get(`/api/v2/product/productdetail/?id=${params.id}`)
    if (result) {
      setProduct(result)
      if (!params.id2) {
        setIsActive(result.isActive)
        setIsApproved(result.isApproved)
      }
    } else {
      setProduct([])
    }
    const orderResult = await get(
      `/api/v2/order/product/count/?prod_id=${params.id}&q=Admin`
    )
    if (orderResult) {
      setOrders(orderResult)
    } else {
      setOrders({})
    }
    if (params.id2) {
      // if (params.type == "map") {
      //   console.log("mapmap");
      //   get(`/api/v2/products/vendor_sellingprice/?prod_id=${params.id}&user_id=${params.id2}`).then(res => {
      //     setMinExtimated(res.min_extimated_delivery_chrg);
      //   }).catch(err => {
      //   })
      // } else {

      get(
        `/api/v2/products/vendor_sellingprice/?prod_id=${params.id}&user_id=${params.id2}`
      )
        .then(res => {
          setSellerPrice(res.selling_price)
          setSellerStock(res.stock)
          setIsActive(res.isActive)
          setIsApproved(res.isApproved)
          setMinExtimated(res.min_extimated_delivery_chrg)
        })
        .catch(err => {
          setSellerPrice(0)
          setSellerStock(0)
        })
      // }
    }
  }
  const actionsClick = async type => {
    let result = null
    setIsLoader(true)
    if (type == "remove") {
      post(`/api/v2/product/removeproduct/`, { id: params.id })
        .then(res => {
          success(`Able to ${type} product`)
          setIsLoader(false)
        })
        .catch(err => {
          error(err?.response?.data?.message)
          setIsLoader(false)
        })
    } else if (type == "approve") {
      if (params.id2) {
        post(`/api/v2/product/vendor_product_approval/`, {
          id: params.id,
          user_id: params.id2,
          isApproved: true,
        })
          .then(res => {
            success(`Able to ${type} product`)
            setIsLoader(false)
            getProductDetails()
          })
          .catch(err => {
            error(err?.response?.data?.message)
            setIsLoader(false)
          })
      } else {
        post(`/api/v2/product/product_approval/`, { id: params.id })
          .then(res => {
            success(`Able to ${type} product`)
            setIsLoader(false)
            getProductDetails()
          })
          .catch(err => {
            error(err?.response?.data?.message)
            setIsLoader(false)
          })
      }
    } else if (type == "inactive") {
      if (params.id2) {
        post(`/api/v2/product/inactivevendorproduct/`, {
          id: params.id,
          user_id: params.id2,
          isActive: false,
        })
          .then(res => {
            success(`Able to ${type} product`)
            setIsLoader(false)
            getProductDetails()
          })
          .catch(err => {
            error(err?.response?.data?.message)
            setIsLoader(false)
          })
      } else {
        post(`/api/v2/product/inactiveproduct/`, {
          prod_id: params.id,
          isActive: false,
        })
          .then(res => {
            success(`Able to ${type} product`)
            setIsLoader(false)
            getProductDetails()
          })
          .catch(err => {
            error(err?.response?.data?.message)
            setIsLoader(false)
          })
      }
    } else if (type == "active") {
      if (params.id2) {
        post(`/api/v2/product/inactivevendorproduct/`, {
          id: params.id,
          user_id: params.id2,
          isActive: true,
        })
          .then(res => {
            success(`Able to ${type} product`)
            getProductDetails()
            setIsLoader(false)
          })
          .catch(err => {
            error(err?.response?.data?.message)
            setIsLoader(false)
          })
      } else {
        post(`/api/v2/product/inactiveproduct/`, {
          prod_id: params.id,
          isActive: true,
        })
          .then(res => {
            success(`Able to ${type} product`)
            setIsLoader(false)
            getProductDetails()
          })
          .catch(err => {
            error(err?.response?.data?.message)
            setIsLoader(false)
          })
      }
    }
  }
  useEffect(() => {
    if (params) {
      getProductDetails(1)
    }
  }, [params])
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const imageShow = (images, id) => {
    const expandImg = document.getElementById("expandedImg" + id)
    expandImg.src = images.startsWith("https://") ? images : `https://${images}`
  }
  const generateImages = image => {
    let items = []
    image &&
      image.forEach(function (images, index) {
        items.push(
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === index,
              })}
              onClick={() => {
                toggleTab(index)
              }}
            >
              <img
                src={
                  images.startsWith("https://") ? images : `https://${images}`
                }
                alt=""
                onClick={() => {
                  imageShow(images, index)
                }}
                className="img-fluid mx-auto d-block rounded"
              />
            </NavLink>
          </NavItem>
        )
      })
    return items
  }

  const generateTabs = image => {
    let items = []
    image &&
      image.forEach(function (images, index) {
        items.push(
          <TabPane tabId={index}>
            <div>
              <img
                src={
                  images.startsWith("https://") ? images : `https://${images}`
                }
                alt=""
                id={`expandedImg${index}`}
                className="img-fluid mx-auto d-block"
              />
            </div>
          </TabPane>
        )
      })
    return items
  }
  const [modal, setModal] = useState(false)
  const [priceModal, setPriceModal] = useState(false)
  const [stockModal, setStockModal] = useState(false)
  const toggle = () => setModal(!modal)
  const toggle1 = () => setPriceModal(!priceModal)
  const toggle2 = () => setStockModal(!stockModal)
  const [mainLoader, setMainLoader] = useState(false)

  const ModalPriceEdit = props => {
    const { buttonLabel, className } = props
    const [sellingPrice, setSellingPrice] = useState(sellerPrice ?? 0)
    const [stock, setStock] = useState(sellerStock ?? 0)
    const [errorMsg, setErrorMsg] = useState(null)
    const [loader, setLoader] = useState(false)
    const onChangeItems = (e, type) => {
      if (type == "sellingPrice") {
        setSellingPrice(e.target.value)
      } else {
        setStock(e.target.value)
      }
    }
    const onClickSubmit = () => {
      if (!stock) {
        setErrorMsg("Please Add Stock")
        return
      }
      if (!sellingPrice) {
        setErrorMsg("Please Add Selling Price")
        return
      }
      const token = JSON.parse(localStorage.getItem("authUser"))
      const inputs = {
        user_id: token.id,
        prod_id: params.id,
        stock: parseFloat(stock),
        selling_price: parseFloat(sellingPrice),
        user_id: params.id2,
      }
      setLoader(true)
      post("/api/v2/products/vendorproducts/", inputs)
        .then(res => {
          toggle1()
          getProductDetails()
          success("Able to update price")
          setLoader(false)
        })
        .catch(err => {
          error("Sorry not able to add details")
          setLoader(false)
        })
    }
    return (
      <div>
        <Button color="danger" onClick={toggle1}>
          {buttonLabel}
        </Button>
        <Modal isOpen={priceModal} toggle={toggle1} className={className}>
          <ModalHeader toggle={toggle1}>PRICE DETAILS</ModalHeader>
          <ModalBody>
            {loader && <Loader />}
            <Row>
              <Col lg="6">
                <Row>
                  <Col lg="6" style={{ marginBottom: "10px" }}>
                    <span style={{ fontWeight: "bold" }}>Selling Price</span>
                  </Col>
                  <Col lg="6" style={{ marginBottom: "10px" }}>
                    <Input
                      name="sellingPrice"
                      type="number"
                      value={sellingPrice}
                      onChange={e => onChangeItems(e, "sellingPrice")}
                    ></Input>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" style={{ marginBottom: "10px" }}>
                    <span style={{ fontWeight: "bold" }}>Stocks</span>
                  </Col>
                  <Col lg="6" style={{ marginBottom: "10px" }}>
                    <Input
                      name="stocks"
                      type="number"
                      value={stock}
                      onChange={e => onChangeItems(e, "stocks")}
                    ></Input>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => onClickSubmit()}>
              Submit
            </Button>
            <Button color="secondary" onClick={toggle1}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
  const ModalStockEdit = props => {
    const { buttonLabel, className } = props
    const [stock, setStock] = useState(sellerStock)
    const [errorMsg, setErrorMsg] = useState(null)
    const [loader, setLoader] = useState(false)
    const onChangeItems = (e, type) => {
      setStock(e.target.value)
    }
    const onClickSubmit = () => {
      // console.log(stock, sellerStock, "onClickSubmitonClickSubmit");

      if (stock == sellerStock) {
        setErrorMsg("Please Change Stock")
        return
      }
      const inputs = {
        prod_id: params.id,
        stock: parseFloat(stock),
        user_id: params.id2,
      }
      setLoader(true)
      post("/api/v2/products/updatevendorproducts/", inputs)
        .then(res => {
          toggle2()
          getProductDetails()
          success("Able to update stock")
          setLoader(false)
        })
        .catch(err => {
          error(err?.response?.data?.message)
          setLoader(false)
        })
    }
    return (
      <div>
        {/* <Button color="danger" onClick={toggle2}>{buttonLabel}</Button> */}
        <Modal isOpen={stockModal} toggle={toggle2} className={className}>
          <ModalHeader toggle={toggle2}>UPDATE STOCK </ModalHeader>
          <ModalBody>
            {loader && <Loader />}
            <Row>
              <Col lg="8">
                <Row>
                  <Col lg="4" style={{ marginBottom: "10px" }}>
                    <span style={{ fontWeight: "bold" }}>Stocks</span>
                  </Col>
                  <Col lg="6" style={{ marginBottom: "10px" }}>
                    <Input
                      name="stocks"
                      type="number"
                      value={stock}
                      onChange={e => onChangeItems(e, "stocks")}
                    />
                    <div style={{ color: "red", height: "14px" }}>
                      {errorMsg}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => onClickSubmit()}>
              Submit
            </Button>
            <Button color="secondary" onClick={toggle2}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
  return (
    <React.Fragment>
      <ModalPriceDetails
        mainGst={product.GST}
        mrp={product.MRP}
        selling_price={sellerPrice}
        toggle={toggle}
        modal={modal}
        prod_id={params.id}
        user_id={params.id2}
        isEdit={true}
        min_extimated_delivery_chrg={minExtimated}
      />
      <ModalPriceEdit />
      <ModalStockEdit />
      <div className="page-content">
        <MetaTags>
          <title>Product Detail | BrakeUp</title>
        </MetaTags>
        <Container fluid>
          {isLoader && <Loader />}
          <Breadcrumbs title="Commercial" breadcrumbItem="Product Detail" />
          {!isEmpty(product) && (
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    {mainLoader && <Loader />}
                    <Row>
                      <Col xl="6" style={{ textAlign: "center" }}>
                        <div className="product-detai-imgs">
                          <Row>
                            <Col md="2" xs="3">
                              <Nav className="flex-column" pills>
                                {generateImages(product.image)}
                              </Nav>
                            </Col>
                            <Col md={{ size: 7, offset: 1 }} xs="9">
                              <TabContent activeTab={activeTab}>
                                {generateTabs(product.image)}
                              </TabContent>
                            </Col>
                          </Row>
                        </div>
                        <div className="mt-4 mt-xl-3">
                          <Row>
                            <Col md="6">
                              <span className="text-muted me-2">
                                PRODUCT NAME
                              </span>
                              <h6 className="mt-1 mb-3">{product.prod_name}</h6>
                            </Col>
                            <Col md="6">
                              <span className="text-muted me-2">
                                BRAND NAME
                              </span>
                              <h6 className="mt-1 mb-3">{product.brand}</h6>
                            </Col>
                          </Row>
                          <Row className="mt-4 mt-xl-3">
                            <Col md="4">
                              <span className="text-muted me-2">
                                PRODUCT CATEGORY
                              </span>
                              <h6 className="mt-1 mb-3">{product.category}</h6>
                            </Col>
                            <Col md="4">
                              <span className="text-muted me-2">
                                PRODUCT TYPE
                              </span>
                              <h6 className="mt-1 mb-3">{product.type}</h6>
                            </Col>
                            <Col md="4">
                              <span className="text-muted me-2">AXLE</span>
                              <h6 className="mt-1 mb-3">{product.axle}</h6>
                            </Col>
                          </Row>
                          <Row className="mt-4 mt-xl-3">
                            <Col
                              md="12"
                              className="mb-4"
                              style={{ textAlign: "center" }}
                            >
                              <span
                                style={{ fontWeight: "bold" }}
                                className="text-muted me-2"
                              >
                                DIMENSTION
                              </span>
                            </Col>
                            <Col md="3">
                              <span className="text-muted me-2">LENGTH</span>
                              <h6 className="mt-1 mb-3">
                                {product.package_length} cm
                              </h6>
                            </Col>
                            <Col md="3">
                              <span className="text-muted me-2">BREADTH</span>
                              <h6 className="mt-1 mb-3">
                                {product.package_breadth} cm
                              </h6>
                            </Col>
                            <Col md="3">
                              <span className="text-muted me-2">HEIGHT</span>
                              <h6 className="mt-1 mb-3">
                                {product.package_height} cm
                              </h6>
                            </Col>
                            <Col md="3">
                              <span className="text-muted me-2">WEIGHT</span>
                              <h6 className="mt-1 mb-3">
                                {product.product_weight} Kg
                              </h6>
                            </Col>
                          </Row>
                          <Row className="mt-4 mt-xl-3">
                            <Col md="6">
                              <span className="text-muted me-2">
                                WEAR INDICATOR
                              </span>
                              <h6 className="mt-1 mb-3">
                                {product.wear_indicator}
                              </h6>
                            </Col>
                            <Col md="6">
                              <span className="text-muted me-2">
                                HEIGHT OF PRODUCT
                              </span>
                              <h6 className="mt-1 mb-3">
                                {product.product_height} mm
                              </h6>
                            </Col>
                            <Col md="6">
                              <span className="text-muted me-2">WIDTH</span>
                              <h6 className="mt-1 mb-3">
                                {product.product_width} mm
                              </h6>
                            </Col>
                            <Col md="6">
                              <span className="text-muted me-2">THICKNESS</span>
                              <h6 className="mt-1 mb-3">
                                {product.product_thickness} mm
                              </h6>
                            </Col>
                            <Col md="6">
                              <span className="text-muted me-2">DIAMETER</span>
                              <h6 className="mt-1 mb-3">
                                {product.product_diameter} mm
                              </h6>
                            </Col>
                            <Col md="6">
                              <span className="text-muted me-2">CENTERING</span>
                              <h6 className="mt-1 mb-3">
                                {product.centering} mm
                              </h6>
                            </Col>
                            <Col md="6">
                              <span className="text-muted me-2">
                                MIN THICKNESS
                              </span>
                              <h6 className="mt-1 mb-3">
                                {product.min_thickness} mm
                              </h6>
                            </Col>
                            <Col md="6">
                              <span className="text-muted me-2">
                                TIGHTENING TORQUE
                              </span>
                              <h6 className="mt-1 mb-3">
                                {product.tightening_torque} Nm
                              </h6>
                            </Col>
                            <Col md="6">
                              <span className="text-muted me-2">
                                NUMBER OF HOLES
                              </span>
                              <h6 className="mt-1 mb-3">
                                {product.no_of_holes}
                              </h6>
                            </Col>
                            <Col md="6">
                              <span className="text-muted me-2">
                                UNIT PER BOX
                              </span>
                              <h6 className="mt-1 mb-3">
                                {product.unit_per_box}
                              </h6>
                            </Col>
                          </Row>
                          {sellerPrice > 0 && (
                            <>
                              <span className="text-muted me-2">
                                SELLING PRICE
                              </span>
                              <h6 className="mt-1 mb-3">{sellerPrice}</h6>
                            </>
                          )}
                          <span className="text-muted me-2">MRP</span>
                          <h6 className="mt-1 mb-3">{product.MRP}</h6>
                          <Row>
                            <Col md="6">
                              <span className="text-muted me-2">
                                SELLER PART NUMBER
                              </span>
                              <h6 className="mt-1 mb-3">
                                {product.seller_part_no}
                              </h6>
                            </Col>
                            <Col md="6">
                              <span className="text-muted me-2">
                                OEM PART NUMBER
                              </span>
                              <h6 className="mt-1 mb-3">
                                {product.oem_part_no}
                              </h6>
                            </Col>
                          </Row>
                          <span className="text-muted me-2">TAGS</span>
                          <h6 className="mt-1 mb-3">{product.tags}</h6>

                          <span className="text-muted me-2">DESCRIPTIONS</span>
                          <h6 className="mt-1 mb-3">{product.description}</h6>

                          <span className="text-muted me-2">HIGHLIGHTS</span>
                          <Row className="mt-4 mt-xl-3">
                            {product.highlights &&
                              product.highlights.map(item => {
                                return (
                                  <Col md="4">
                                    <h6 className="mt-1 mb-3">{item}</h6>
                                  </Col>
                                )
                              })}
                          </Row>
                        </div>
                        <Row>
                          <Col style={{ textAlign: "center" }}>
                            <div className="mb-3">
                              <div className="text-muted">
                                <div className="">
                                  <p className="text-muted">PERFORMANCE</p>
                                </div>
                                <div>
                                  <StarRatings
                                    rating={
                                      product.performance
                                        ? product.performance
                                        : 0
                                    }
                                    starRatedColor="#F1B44C"
                                    starEmptyColor="#2D363F"
                                    numberOfStars={5}
                                    name="rating"
                                    starDimension="14px"
                                    starSpacing="3px"
                                  />
                                </div>
                              </div>
                            </div>
                          </Col>

                          <Col>
                            <div className="mb-3">
                              <div className="text-muted">
                                <p className="text-muted">DUST</p>
                                <div>
                                  <StarRatings
                                    rating={product.dust ? product.dust : 0}
                                    starRatedColor="#F1B44C"
                                    starEmptyColor="#2D363F"
                                    numberOfStars={5}
                                    name="rating"
                                    starDimension="14px"
                                    starSpacing="3px"
                                  />
                                </div>
                              </div>
                            </div>
                          </Col>

                          <Col>
                            <div className="mb-3">
                              <p className="text-muted">SQUEAL</p>
                              <div className="text-muted">
                                <div>
                                  <StarRatings
                                    rating={product.squeal ? product.squeal : 0}
                                    starRatedColor="#F1B44C"
                                    starEmptyColor="#2D363F"
                                    numberOfStars={5}
                                    name="rating"
                                    starDimension="14px"
                                    starSpacing="3px"
                                  />
                                </div>
                              </div>
                            </div>
                          </Col>

                          <Col>
                            <div className="mb-3">
                              <p className="text-muted">LIFE</p>
                              <div>
                                <div>
                                  <StarRatings
                                    rating={product.life ? product.life : 0}
                                    starRatedColor="#F1B44C"
                                    starEmptyColor="#2D363F"
                                    numberOfStars={5}
                                    name="rating"
                                    starDimension="14px"
                                    starSpacing="3px"
                                  />
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      <Col xl="6">
                        {!params.id2 && (
                          <div style={{ float: "right" }}>
                            <Button
                              type="button"
                              color="primary"
                              className="btn  mt-2 me-1"
                              onClick={() =>
                                history.push(
                                  `/update-product-detail/${params.id}`
                                )
                              }
                            >
                              Edit Product
                            </Button>
                          </div>
                        )}
                        {params.id2 && params.type != "map" && (
                          <div className="mt-4 mt-xl-3">
                            <span className="text-muted me-2">
                              SELLER STOCK
                            </span>
                            <EditOutlined onClick={() => setStockModal(true)} />
                            <h4 className="mt-1 mb-3">{sellerStock}</h4>
                          </div>
                        )}
                        <div className="mt-4 mt-xl-3">
                          <span className="text-muted me-2">TOTAL STOCK</span>
                          <h4 className="mt-1 mb-3">{product.total_stock}</h4>
                        </div>
                        <div className="mt-5">
                          <h3>COMPATIBLE VEHICLES</h3>
                          <div className="table-responsive">
                            <Table className="table mb-0 table-bordered">
                              <tbody>
                                <tr key="header">
                                  <th
                                    scope="row"
                                    // style={{ width: "150px" }}
                                    className={"text-capitalize"}
                                  >
                                    Make
                                  </th>
                                  <td>Model</td>
                                  <td>Version</td>
                                  <td>Fuel</td>
                                  <td>From Year</td>
                                  <td>To Year</td>
                                </tr>
                                {product.compatible_vehicles &&
                                  product.compatible_vehicles.map(
                                    (specification, i) => (
                                      <tr key={i}>
                                        <th
                                          scope="row"
                                          // style={{ width: "150px" }}
                                          className={"text-capitalize"}
                                        >
                                          {
                                            specification.compatible_vehicle_make
                                          }
                                        </th>
                                        <td>
                                          {
                                            specification.compatible_vehicle_model
                                          }
                                        </td>
                                        <td>
                                          {specification?.compatible_vehicle_version ??
                                            ""}
                                        </td>
                                        <td>
                                          {specification?.compatible_vehicle_fuel_type ??
                                            ""}
                                        </td>
                                        <td>
                                          {
                                            specification.compatible_vehicle_from_year
                                          }
                                        </td>
                                        <td>
                                          {specification.isPresent
                                            ? new Date().getFullYear()
                                            : specification.compatible_vehicle_to_year}
                                        </td>
                                      </tr>
                                    )
                                  )}
                              </tbody>
                            </Table>
                          </div>
                        </div>

                        {!params.id2 && (
                          <div className="mt-5">
                            <h3>SELLERS</h3>
                            <div className="table-responsive">
                              <Table className="table mb-0 table-bordered">
                                <tbody>
                                  <tr key="header">
                                    <th
                                      scope="row"
                                      style={{ width: "200px" }}
                                      className={"text-capitalize"}
                                    >
                                      Name
                                    </th>
                                    <td>Selling Price</td>
                                  </tr>
                                  {product.sellers &&
                                    product.sellers.map((specification, i) => (
                                      <tr key={i}>
                                        <th
                                          scope="row"
                                          style={{ width: "200px" }}
                                          className={"text-capitalize"}
                                        >
                                          {specification.name}
                                        </th>
                                        <td>{specification.selling_price}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        )}
                        <div className="mt-5">
                          <h3>ORDERS</h3>
                          <div className="table-responsive">
                            <Table className="table mb-0 table-bordered">
                              <tbody>
                                <tr key="header">
                                  <th
                                    scope="row"
                                    style={{ width: "200px" }}
                                    className={"text-capitalize"}
                                  >
                                    OPEN ORDERS
                                  </th>
                                  <td>COMPLETED CRDERS</td>
                                  <td>RETURNED ORDERS</td>
                                </tr>

                                <tr>
                                  <th
                                    scope="row"
                                    style={{ width: "200px" }}
                                    className={"text-capitalize"}
                                  >
                                    {orders.open_order}
                                  </th>
                                  <td>{orders.completed_order}</td>
                                  <td>{orders.returned_order}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </div>
                        <div
                          className="mt-4 mt-xl-3"
                          style={{ textAlign: "center" }}
                        >
                          {params.id2 && (
                            <div>
                              <Button
                                type="button"
                                color="success"
                                className="btn  mt-2 me-1"
                                onClick={() => setModal(true)}
                              >
                                PRICE DETAILS
                              </Button>
                            </div>
                          )}
                        </div>
                        <div className="mt-4 mt-xl-3">
                          {params.id2 && (
                            <div style={{ float: "right" }}>
                              <Button
                                type="button"
                                className="btn  mt-2 me-1"
                                onClick={() => history.goBack()}
                              >
                                BACK
                              </Button>
                            </div>
                          )}
                          {!params.type && isApproved == false && (
                            <div style={{ float: "right" }}>
                              <Button
                                type="button"
                                color="success"
                                className="btn  mt-2 me-1"
                                onClick={() => actionsClick("approve")}
                              >
                                Approve Product
                              </Button>
                            </div>
                          )}
                          {!params.type && (
                            <div style={{ float: "right" }}>
                              <Button
                                type="button"
                                color="danger"
                                className="btn  mt-2 me-1"
                                // onClick={() => actionsClick("remove")}
                              >
                                Send Query
                              </Button>
                            </div>
                          )}
                          {!params.type && isActive == true && (
                            <div style={{ float: "right" }}>
                              <Button
                                type="button"
                                color="warning"
                                className="btn  mt-2 me-1"
                                onClick={() => actionsClick("inactive")}
                              >
                                Inactive Product
                              </Button>
                            </div>
                          )}
                          {!params.type && isActive == false && (
                            <div style={{ float: "right" }}>
                              <Button
                                type="button"
                                color="warning"
                                className="btn  mt-2 me-1"
                                onClick={() => actionsClick("active")}
                              >
                                Active Product
                              </Button>
                            </div>
                          )}
                          {params.type == "map" && (
                            <div style={{ float: "right" }}>
                              <Button
                                type="button"
                                color="warning"
                                className="btn  mt-2 me-1"
                                onClick={() => setPriceModal(true)}
                              >
                                Map
                              </Button>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

EcommerceProductDetail.propTypes = {
  product: PropTypes.object,
  onGetProductDetail: PropTypes.func,
}

export default EcommerceProductDetail
