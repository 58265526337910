import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Badge,
  DropdownItem,
  Row,
  Col,
  Table,
} from "reactstrap"
import companies from "assets/images/companies"

import { Link } from "react-router-dom"
import moment from "moment"

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import { get, post } from "../../helpers/api_helper"
import { success, error, Loader } from "../../service/alerts"
import { useHistory } from "react-router-dom"

const ProductRow = props => {
  const [modal, setmodal] = useState(false)
  // const [pendingApprovals, setPendingApprovals] = useState([]);
  const history = useHistory()
  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
  }))

  const getPendingApprovals = async () => {
    let order_id = window.location.pathname.split("/").reverse()[0]
    const result = await get(
      `/api/v2/seller/seller/productmaplist/?id=${order_id}`
    )
    if (result) {
      props.setPendingApprovals(result[0])
    }
  }

  useEffect(() => {
    getPendingApprovals()
  }, [])

  const mapItems = items => {
    const path = history.location.pathname.split("/")
    history.push(
      `/ecommerce-product-get-unmaped/${items["_id"]["$oid"]}/${
        path[path.length - 1]
      }/map`
    )
    // let order_id = window.location.pathname.split('/').reverse()[0];
    // post("/api/v2/products/vendorproducts/ ", {"prod_id": items["_id"]["$oid"],
    // "vend_id":order_id})
    // .then(res=>{
    //   success("Able to map products");
    //   getPendingApprovals();
    // }).catch(err=>{
    //   error(
    //     "Not able to map products"
    //   )
    // })
  }
  return (
    <React.Fragment>
      <tr className={`tr-border-${props.color} mb-2`}>
        <td>
          <div className="text-muted mb-1 p-2 ">Brand Name</div>
        </td>
        <td>
          <div className="text-muted mb-1 p-2 ">Image</div>
        </td>

        <td>
          <div className="text-muted mb-1 p-2 ">Seller part number</div>
        </td>
        <td>
          <div className="text-muted mb-1 p-2 ">Brakeup part number</div>
        </td>
        <td>
          <div className="text-muted mb-1 p-2 ">Category</div>
        </td>
        <td>
          <div className="text-muted mb-1 p-2 ">Type</div>
        </td>
        <td>
          <div className="text-muted mb-1 p-2 ">Axle</div>
        </td>
        <td>
          <div className="text-muted mb-1 p-2 ">OEM part number</div>
        </td>
        {/* <td>
          <div className="text-muted mb-1 p-2 " >
            Action
          </div>
        </td> */}
      </tr>
      {props.pendingApprovals &&
        props.pendingApprovals.map(items => {
          return (
            <tr
              className={`tr-border-${props.color} mb-2`}
              onClick={() => mapItems(items)}
            >
              <td>
                <div className="text-muted mb-1 p-2 ">{items.brand}</div>
              </td>
              <td>
                <img
                  src={
                    items.image && items.image.length > 0
                      ? items.image[0].startsWith("https://")
                        ? items.image[0]
                        : `https://${items.image[0]}`
                      : ""
                  }
                  alt=""
                  className="avatar-sm"
                />
              </td>

              <td>
                <div className="text-muted mb-1 p-2 ">
                  {items.seller_part_no}
                </div>
              </td>
              <td>
                <div className="text-muted mb-1 p-2 ">
                  {items.brakeup_part_no}
                </div>
              </td>
              <td>
                <div className="text-muted mb-1 p-2 ">{items.category}</div>
              </td>
              <td>
                <div className="text-muted mb-1 p-2 ">{items.type}</div>
              </td>
              <td>
                <div className="text-muted mb-1 p-2 ">{items.axle}</div>
              </td>
              <td>
                <div className="text-muted mb-1 p-2 ">{items.oem_part_no}</div>
              </td>
              {/* <td onClick={()=> mapItems(items)}>
              <div className="text-muted mb-1 p-2 " >
                Map
              </div>
            </td> */}
            </tr>
          )
        })}
    </React.Fragment>
  )
}

ProductRow.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(ProductRow)
