import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  Button,
} from "reactstrap"
import classnames from "classnames"
import { isEmpty, map } from "lodash"

//Import Star Ratings
import StarRatings from "react-star-ratings"

// RangeSlider
import Nouislider from "nouislider-react"
import "nouislider/distribute/nouislider.css"

//Import Product Images
import { productImages } from "assets/images/product"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import data
import { discountData, productsData } from "common/data"

//Import actions
import { getProducts as onGetProducts } from "store/e-commerce/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { get } from "../../../helpers/api_helper"
import Select from "react-select"
import PerfectScrollbar from "react-perfect-scrollbar"

const EcommerceProducts = props => {
  const dispatch = useDispatch()
  const { history } = props
  const [productList, setProductList] = useState([])
  const [activeTab, setActiveTab] = useState("1")
  const [search, setSearch] = useState("")
  const [filter, setFilter] = useState([])
  const [last_id, setLastId] = useState("")
  const [limit, setLimit] = useState(10)
  const [filtervalue, setFiltervalue] = useState("")
  const [ParamURL, setParamURL] = useState("")
  const [ProductListURL, setProductListURL] = useState(
    "/api/v2/search/?q=product&order=-1&&sort_by=created_at&limit=20"
  )

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const [products, setProducts] = useState([])
  // const getProducts = async () => {
  //   const result = await get(`/api/v2/products/product/?order=-1&&sort_by=created_at&limit=20`);
  //   if (result.data) {
  //     setProductList(result.data)
  //   } else {
  //     setProductList([]);
  //   }
  // }
  // useEffect(() => {
  //   getProducts();
  // }, [])

  const urltoFunction = () => {
    var url_string = location.href //window.location.href
    var url = new URL(url_string)
    var s = url.searchParams.get("search") ?? ""

    const ProductFilterSearchToken = JSON.parse(
      localStorage.getItem("ProductFilterSearch")
    )
    setFilter(ProductFilterSearchToken)

    if (s || ProductFilterSearchToken) {
      setSearch(s ? s : "")
      onSearchChange(s, ProductFilterSearchToken)
    } else {
      onSearchChange()
    }

    // if (ProductFilterSearchToken && ProductFilterSearchToken.length != 0) {
    //   onSearchChange(s, ProductFilterSearchToken)
    // }

    // console.log(s, ProductFilterSearchToken, "urltoFunction")

    // let filterkeys = []
    // let filterValue = []
    // for (const key of url.searchParams.keys()) {
    //   filterkeys.push(key)
    // }
    // filterkeys && filterkeys.forEach((elem) => {
    //   if (elem == "all") {
    //     filterValue.push({ label: "All Products", value: "all" })
    //   }
    //   if (elem == "stock") {
    //     filterValue.push({ label: "Zero Stock Products", value: "0" })
    //   }
    //   if (elem == "total_stock") {
    //     filterValue.push({ label: "Low Stock", value: "low_stock" })
    //   }
    //   if (elem == "in_active") {
    //     filterValue.push({ label: "Inactive Products", value: "in_active" })
    //   }
    //   if (elem == "active") {
    //     filterValue.push({ label: "Active Products", value: "active" })
    //   }
    //   if (elem == "new_product") {
    //     filterValue.push({ label: "New Product Within Month", value: "new_product" })
    //   }
    //   if (elem == "frequently_ordered") {
    //     filterValue.push({ label: "Frequently Ordered", value: "frequently_ordered" })
    //   }
    //   if (elem == "isApproved") {
    //     filterValue.push({ label: "Pending Approval", value: "isApproved" })
    //   }
    // })

    //   setFilter(filterValue)
    //   onSearchChange(search, filter)
  }

  useEffect(() => {
    urltoFunction()
  }, [location.href])

  // useEffect(() => {
  //   get(ProductListURL).then(res => {
  //     setProductList(res.data);
  //   }).catch(err => {
  //     setProductList([]);
  //   })

  // }, []);

  const onSearchChange = (searchValue, filterValue) => {
    // console.trace("getFiltergetFilter")
    // console.log(filter, " onSearchChangeonSearchChange");
    let url = "/api/v2/search/?q=product&order=-1&&sort_by=created_at&limit=20"
    let paramurl = "/products"
    let filterparam = ""
    if (searchValue) {
      url = `${url}&s=${searchValue}`
    }
    if (filterValue) {
      let filter = ""
      filterValue &&
        filterValue.map(items => {
          if (items.value == "all") {
            filter = `${filter}&all=True`
          }
          if (items.value == "0") {
            filter = `${filter}&stock=0`
          }
          if (items.value == "low_stock") {
            filter = `${filter}&total_stock=5`
          }
          if (items.value == "in_active") {
            filter = `${filter}&inactive=False`
          }
          if (items.value == "active") {
            filter = `${filter}&active=True`
          }
          if (items.value == "new_product") {
            filter = `${filter}&new_products=new`
          }
          // if (items.value == "frequently_ordered") {
          //   filter = `${filter}&frequently_ordered=True`
          // }
          if (items.value == "isApproved") {
            filter = `${filter}&isApproved=False`
          }
        })
      url = `${url}${filter}`
      filterparam = filter
      setFiltervalue(filter)
    }
    setProductListURL(url)
    setSearch(searchValue)
    setFilter(filterValue)
    localStorage.setItem(
      "ProductFilterSearch",
      JSON.stringify(filterValue ?? [])
    )

    get(url)
      .then(res => {
        setProductList(res.data)
      })
      .catch(err => {
        setProductList([])
      })

    if (searchValue) {
      paramurl = `${paramurl}?search=${searchValue ? searchValue : ""}`
    }
    // if (filterparam) {
    //   let seperator = searchValue == null ? "?" : ""
    //   paramurl = `${paramurl}${seperator}${filterparam ? filterparam : ""}`
    // }
    if (paramurl != ParamURL) {
      window.history.pushState(null, "", `${paramurl}`)
      setParamURL(paramurl)
      // console.log(paramurl, ParamURL, "chhange in paramurl ")
    }
  }
  const onNext = () => {
    let last_item = productList[productList.length - 1]
    // console.log(last_item, last_id, productList, "onnext");

    if (last_item && last_id != last_item._id.$oid) {
      get(`${ProductListURL}&previousId=${last_item._id.$oid}`)
        .then(response => {
          if (response.data) {
            let new_orders = [...productList, ...response.data]
            setProductList(new_orders)
          }
        })
        .catch(error => {})
      setLastId(last_item._id.$oid)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Products | BrakeUp</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Commercial" breadcrumbItem="Products" />
          <Row>
            <Col lg="12">
              <Row className="mb-3">
                <Col xl="4" sm="6">
                  <div className="mt-2">
                    <Button
                      color="primary"
                      onClick={() => history.push("/ecommerce-add-product")}
                    >
                      Add Product
                    </Button>
                  </div>
                </Col>
                <Col lg="8" sm="6">
                  <Form className="mt-4 mt-sm-0 float-sm-end d-flex align-items-center">
                    <div className="search-box me-2">
                      <div className="position-relative">
                        <Input
                          type="text"
                          className="form-control border-0"
                          placeholder="Search..."
                          onInput={e => {
                            onSearchChange(e.target.value, filter)
                          }}
                          name="search"
                          value={search}
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                    <div style={{ width: "300px" }}>
                      <Select
                        style={{ width: "200px" }}
                        classNamePrefix="select"
                        name="filters"
                        placeholder="Filters"
                        isMulti
                        options={[
                          { label: "All Products", value: "all" },
                          { label: "Zero Stock Products", value: "0" },
                          { label: "Low Stock", value: "low_stock" },
                          { label: "Inactive Products", value: "in_active" },
                          { label: "Active Products", value: "active" },
                          {
                            label: "New Product Within Month",
                            value: "new_product",
                          },
                          // { label: "Frequently Ordered", value: "frequently_ordered" },
                          { label: "Pending Approval", value: "isApproved" },
                        ]}
                        onChange={e => {
                          onSearchChange(search, e)
                          // setFormValue({ "filter": e })
                        }}
                        value={filter}
                      />
                    </div>
                  </Form>
                </Col>
              </Row>
              <Row>
                <PerfectScrollbar
                  onYReachEnd={() => onNext()}
                  style={
                    window.innerWidth >= 992
                      ? {
                          height: "700px",
                          overflowY: "scroll",
                          overflowX: "hidden",
                        }
                      : { overflow: "visible" }
                  }
                >
                  <Row>
                    {!isEmpty(productList) &&
                      productList.map((product, key) => (
                        <Col
                          xl="4"
                          lg="4"
                          sm="6"
                          key={"_col_" + key}
                          className="col-xxl-3"
                        >
                          <Card
                            style={{ border: "0.1px solid" }}
                            onClick={() =>
                              history.push(
                                `/ecommerce-product-detail/${product._id["$oid"]}`
                              )
                            }
                          >
                            <CardBody>
                              <Row>
                                <h5 className="mb-3 text-truncate">
                                  <Link
                                    to={
                                      "/ecommerce-product-detail/" +
                                      product._id.$oid
                                    }
                                    className="text-dark"
                                  >
                                    {product.prod_name}
                                  </Link>
                                </h5>
                              </Row>
                              <Row>
                                <Col className="product-img position-relative">
                                  <img
                                    src={
                                      product.image[0] &&
                                      product.image[0].startsWith("https://")
                                        ? product.image[0]
                                        : `https://${product.image[0]}`
                                    }
                                    alt=""
                                    className="img-fluid mx-auto d-block"
                                    style={{
                                      height: "150px",
                                      minWidth: "150px",
                                    }}
                                  />
                                </Col>
                                <Col>
                                  <span className="text-muted me-2">Brand</span>
                                  <h5 className="mb-3 text-truncate">
                                    {product.brand}
                                  </h5>
                                  <span className="text-muted me-2">
                                    Product Category
                                  </span>
                                  <h5 className="mb-3 text-truncate">
                                    {product.category}
                                  </h5>
                                  <span className="text-muted me-2">MRP</span>
                                  <h5 className="mb-3 text-truncate">
                                    {product.mrp}
                                  </h5>
                                </Col>
                              </Row>
                              {/* <span className="text-muted me-2">Type</span>
                                  <h5 className="mb-3 text-truncate">
                                    {product.type}
                                  </h5> */}
                              <Row>
                                <Col>
                                  <span className="text-muted me-2">
                                    Product ID
                                  </span>
                                  <h5 className="mb-3 text-truncate">
                                    {product.product_id}
                                  </h5>
                                </Col>
                                <Col>
                                  <span className="text-muted me-2">
                                    Seller PrtNo.
                                  </span>
                                  <h5 className="mb-3 text-truncate">
                                    {product.seller_part_no}
                                  </h5>
                                </Col>
                              </Row>

                              <Row>
                                <Col>
                                  <span className="text-muted me-2">
                                    No of Sellers
                                  </span>
                                  <h5 className="mb-3 text-truncate">
                                    {product.no_of_sellers}
                                  </h5>
                                </Col>
                                <Col>
                                  <span className="text-muted me-2">Stock</span>
                                  <h5 className="mb-3 text-truncate">
                                    {product.total_stock}
                                  </h5>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                  </Row>
                </PerfectScrollbar>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

EcommerceProducts.propTypes = {
  products: PropTypes.array,
  history: PropTypes.object,
  onGetProducts: PropTypes.func,
}

export default withRouter(EcommerceProducts)
