import React, { useState, useRef } from "react"
import MetaTags from 'react-meta-tags';

import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    Table,
} from "reactstrap"
import Dropzone from "react-dropzone"
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { CustomInput, CustomSelect, CustomCheckbox } from "../Ecommerce/customInputs";
import { post, get } from "../../helpers/api_helper";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import InCludedProducts from "./inCludedProducts";
import ExCludedProducts from "./exCludedProducts";
import { DisplayDetails } from "./displayDetails";

import { success, error, Loader } from "../../service/alerts";
import moment from "moment";

const AddPromoCode = props => {

    const [showOther, setShowOther] = useState(1);
    const [loader, setLoader] = useState(false);
    const [message, setMessage] = useState("");
    const [firstForm, setFirstForm] = useState({});
    const [secondForm, setSecondForm] = useState({});
    const [thirdForm, setThirdForm] = useState({});
    const [ischecked, setischecked] = useState(false)

    const history = useHistory();

    const FirstForm = (props) => {
        const { handleSubmit, errors, control, setValue, watch, register, unregister } = useForm({
            mode: 'onChange'
        });
        const myRef = useRef();
        var date = new Date();
        var end_date = new Date();
        date.setDate(date.getDate() - 1)


        const [validateFrom, setValidateFrom] = useState(date);
        const [validateTo, setValidateTo] = useState(end_date);
        const [address, setAddress] = useState([]);

        useEffect(() => {
            if (props && Object.keys(props.firstForm).length > 0) {

                setValue("type", props?.firstForm?.type);
                setValue("promo_type", props?.firstForm?.promo_type);
                setValue("shipping_charge", props?.firstForm?.shipping_charge);
                setValue("choice_of_phrase", props?.firstForm?.choice_of_phrase);
                setValue("max_cart_value", props?.firstForm?.max_cart_value);
                setValue("max_discount", props?.firstForm?.max_discount);
                setValue("min_product_value", props?.firstForm?.min_product_value);
                setValue("min_cart_value", props?.firstForm?.min_cart_value);
                setValue("no_of_use", props?.firstForm?.no_of_use);
                setValue("rate", props?.firstForm?.rate);
                setValue("user_id", props?.firstForm?.user_id);
                setValue("value", props?.firstForm?.value);
                setValidateFrom(props?.firstForm?.validity_frm);
                setValidateFrom(props?.firstForm?.validity_to);
                myRef.current.setStartDate(props?.firstForm?.validity_frm);
                myRef.current.setEndDate(props?.firstForm?.validity_to);

            }
        }, [props.firstForm])
        const onSubmit = async (items) => {

            if (!validateFrom || !validateTo) {
                error("Validate From - To is required");
                return;
            }
            // items.shipping_charge = items.shipping_charge ?? false;
            items.validity_frm = validateFrom;
            items.validity_to = validateTo;
            props.getValuesFromForm1(items)
        }
        const addressDetails = (values) => {
            let obj = [...address];
            obj.push(values);
            setAddress(obj);
        }
        const validatePhone = (number) => {
            // Phone number validations
            var re = /^[0][1-9]\d{9}$|^[1-9]\d{9,10}$/g;
            return re.test(number);
        };
        const handleCallback = (start, end, label) => {
            setValidateFrom(start);
            setValidateTo(end);
        }


        return (<Row>
            <Col lg={12}>
                <Card>
                    <CardBody>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col lg={6}>
                                    <div className="row mb-4">
                                        <Label
                                            htmlFor="horizontal-email-Input"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Type
                                        </Label>
                                        <Col sm={9}>
                                            <Controller
                                                as={<CustomSelect />}
                                                defaultValue={null}
                                                name={"type"}
                                                options={[{ value: "FirstOrder", label: "First Order" },
                                                { value: "AnyOrder", label: "Any Order" }]}
                                                onChange={([e]) => {
                                                    return { value: e };
                                                }}
                                                control={control}
                                                rules={{ required: true }}
                                            />
                                            {errors.type && <span style={{ color: "red" }}>Type is required.</span>}
                                        </Col>
                                    </div>
                                    <div className="row mb-4">
                                        <Label
                                            htmlFor="horizontal-email-Input"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Promo User-type
                                        </Label>
                                        <Col sm={9}>
                                            <Controller
                                                as={<CustomSelect />}
                                                defaultValue={null}
                                                name={"promo_type"}
                                                options={[
                                                    { value: "0", label: "Registered User" },
                                                    { value: "1", label: "Common User" },
                                                    { value: "2", label: "New User" }]}
                                                onChange={([e]) => {
                                                    return { value: e };
                                                }}
                                                control={control}
                                                rules={{ required: true }}
                                            />
                                            {errors.promo_type && <span style={{ color: "red" }}>Promo User-type is required.</span>}
                                        </Col>
                                    </div>
                                    <div className=" d-flex row mb-4 align-items-center">
                                        <Label
                                            htmlFor="horizontal-email-Input"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Is Shipping charge applicable
                                        </Label>

                                        <Col sm={9} className='align-bottom'>

                                            <Controller
                                                as={<CustomCheckbox />}
                                                value={true}
                                                defaultValue={false}
                                                name={"shipping_charge"}
                                                type="checkbox"
                                                onChange={(e) => {
                                                    return { value: e };
                                                }}
                                                control={control}
                                                rules={{ required: false }}
                                            />
                                        </Col>
                                    </div>
                                    <div className="row mb-4">
                                        <Label
                                            htmlFor="horizontal-firstname-Input"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Mobile/mail id
                                        </Label>
                                        <Col sm={9}>
                                            <Controller
                                                as={<CustomInput />}
                                                defaultValue={null}
                                                name="user_id"
                                                onChange={([e]) => {
                                                    return { value: e };
                                                }}
                                                control={control}
                                                type={"text"}
                                                rules={{ required: false }}
                                            />
                                            {errors.user_id &&
                                                errors.user_id.type == "required" &&
                                                <span style={{ color: "red" }}>Mobile/mail id is required.</span>}

                                        </Col>
                                    </div>
                                    <div className="row mb-4">
                                        <Label
                                            htmlFor="horizontal-email-Input"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Value
                                        </Label>
                                        <Col sm={4}>
                                            <Controller
                                                as={<CustomSelect />}
                                                defaultValue={null}
                                                name={"rate"}
                                                options={[{ value: "Rupees", label: "Rupees" },
                                                { value: "Percent", label: "Percent" }]}
                                                onChange={([e]) => {
                                                    return { value: e };
                                                }}
                                                control={control}
                                                rules={{ required: true }}
                                            />
                                            {errors.rate && <span style={{ color: "red" }}>Value is required.</span>}
                                        </Col>
                                        <Col sm={5}>
                                            <Controller
                                                as={<CustomInput />}
                                                defaultValue={null}
                                                name="value"
                                                onChange={([e]) => {
                                                    return { value: e };
                                                }}
                                                control={control}
                                                type={"number"}
                                                rules={{ required: true }}
                                            />
                                            {errors.value && <span style={{ color: "red" }}>Amount is required.</span>}
                                        </Col>
                                    </div>
                                    <div className="row mb-4">
                                        <Label
                                            htmlFor="horizontal-password-Input"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Maxmum Discount value
                                        </Label>
                                        <Col sm={9}>
                                            <Controller
                                                as={<CustomInput />}
                                                defaultValue={null}
                                                name="max_discount"
                                                onChange={([e]) => {
                                                    return { value: e };
                                                }}
                                                control={control}
                                                type={"number"}
                                                rules={{ required: true }}
                                            />
                                            {errors.max_discount && <span style={{ color: "red" }}>Max discount is required.</span>}
                                        </Col>
                                    </div>


                                </Col>
                                <Col lg={6}>
                                    <div className="row mb-4">
                                        <Label
                                            htmlFor="horizontal-password-Input"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Validity From - To
                                        </Label>
                                        <Col sm={9}>
                                            <DateRangePicker onCallback={handleCallback}
                                                initialSettings={{
                                                    startDate: moment(validateFrom), endDate: moment(validateTo),
                                                    locale: {
                                                        'format': 'DD/MMM/YYYY'
                                                    }
                                                }}
                                                ref={myRef}>
                                                <input type="text" className="form-control" name={"date_from_and_to"} />
                                            </DateRangePicker>
                                        </Col>
                                    </div>
                                    <div className="row mb-4">
                                        <Label
                                            htmlFor="horizontal-firstname-Input"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Maxmum cart value
                                        </Label>
                                        <Col sm={9}>
                                            <Controller
                                                as={<CustomInput />}
                                                defaultValue={null}
                                                name="max_cart_value"
                                                onChange={([e]) => {
                                                    return { value: e };
                                                }}
                                                control={control}
                                                type={"number"}
                                                rules={{ required: true }}
                                            />
                                            {errors.max_cart_value && <span style={{ color: "red" }}>Maxmum cart value is required.</span>}

                                        </Col>
                                    </div>
                                    <div className="row mb-4">
                                        <Label
                                            htmlFor="horizontal-firstname-Input"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Minimum cart value
                                        </Label>
                                        <Col sm={9}>
                                            <Controller
                                                as={<CustomInput />}
                                                defaultValue={null}
                                                name="min_cart_value"
                                                onChange={([e]) => {
                                                    return { value: e };
                                                }}
                                                control={control}
                                                type={"number"}
                                                rules={{ required: true }}
                                            />
                                            {errors.min_cart_value && <span style={{ color: "red" }}>Minimum cart value is required.</span>}

                                        </Col>
                                    </div>
                                    <div className="row mb-4">
                                        <Label
                                            htmlFor="horizontal-firstname-Input"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Minimum product value
                                        </Label>
                                        <Col sm={9}>
                                            <Controller
                                                as={<CustomInput />}
                                                defaultValue={null}
                                                name="min_product_value"
                                                onChange={([e]) => {
                                                    return { value: e };
                                                }}
                                                control={control}
                                                type={"number"}
                                                rules={{ required: true }}
                                            />
                                            {errors.min_product_value && <span style={{ color: "red" }}>Minimum product value is required.</span>}

                                        </Col>
                                    </div>
                                    <div className="row mb-4">
                                        <Label
                                            htmlFor="horizontal-firstname-Input"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Number of Use
                                        </Label>
                                        <Col sm={9}>
                                            <Controller
                                                as={<CustomInput />}
                                                defaultValue={null}
                                                name="no_of_use"
                                                onChange={([e]) => {
                                                    return { value: e };
                                                }}
                                                control={control}
                                                type={"number"}
                                                rules={{ required: true }}
                                            />
                                            {errors.no_of_use && <span style={{ color: "red" }}>Number of Use is required.</span>}

                                        </Col>
                                    </div>
                                    <div className="row mb-4">
                                        <Label
                                            htmlFor="horizontal-firstname-Input"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Choice of Phrase
                                        </Label>
                                        <Col sm={9}>
                                            <Controller
                                                as={<CustomInput />}
                                                defaultValue={null}
                                                name="choice_of_phrase"
                                                onChange={([e]) => {
                                                    return { value: e };
                                                }}
                                                control={control}
                                                type={"text"}
                                                rules={{ required: true }}
                                            />
                                            {errors.choice_of_phrase && <span style={{ color: "red" }}>Choice of Phrase is required.</span>}

                                        </Col>
                                    </div>
                                </Col>
                                <Col lg="12" style={{ marginTop: "-37px" }}>
                                    <div className="row mb-4">
                                        <Col sm={3}></Col>
                                        <Col sm={9}>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="w-md"
                                                style={{ float: "right", marginTop: "15px" }}
                                            >
                                                Save and Continue
                                            </Button>
                                        </Col>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                    </CardBody>
                </Card>
            </Col>
        </Row>
        )
    }
    const SecondForm = (props) => {
        const onSubmit = (values) => {
            getValuesFromForm2(values);
        }
        return (
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <CardTitle>{firstForm.choice_of_phrase} <Button color="link" onClick={() => setShowOther(1)} style={{ float: "right" }}>Back</Button></CardTitle>
                            <InCludedProducts onSubmit={onSubmit} secondForm={props.secondForm} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        )
    }
    const ThirdForm = (props) => {
        const onSubmit = (values) => {

            getValuesFromForm3(values);
        }
        return (
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <CardTitle><Button color="link" onClick={() => setShowOther(2)} style={{ float: "right" }}>Back</Button></CardTitle>
                            <ExCludedProducts onSubmit={onSubmit} thirdForm={props.thirdForm} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }
    const getValuesFromForm1 = (values) => {
        setFirstForm(values);
        setShowOther(2);
    }
    const getValuesFromForm2 = (values) => {
        setSecondForm(values);
        setShowOther(3);
        // const obj3 = Object.assign({}, firstForm, values);
    }
    const getValuesFromForm3 = (values) => {
        setThirdForm(values);
        setShowOther(4);
        // const obj3 = Object.assign({}, firstForm, values);
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Promo Code Generation |  Brakeup</title>
                </MetaTags>
                <Container fluid={true}>
                    {loader && <Loader />}
                    {(() => {
                        switch (showOther) {
                            case 1:
                                return <React.Fragment>
                                    <FirstForm getValuesFromForm1={getValuesFromForm1} firstForm={firstForm} />
                                </React.Fragment>
                            case 2:
                                return <React.Fragment>
                                    <SecondForm getValuesFromForm2={getValuesFromForm2} secondForm={secondForm} firstForm={firstForm} />
                                </React.Fragment>
                            case 3:
                                return <React.Fragment>
                                    <ThirdForm getValuesFromForm3={getValuesFromForm3} thirdForm={thirdForm} />
                                </React.Fragment>
                            case 4:
                                return <React.Fragment>
                                    <DisplayDetails firstForm={firstForm}
                                        secondForm={secondForm}
                                        thirdForm={thirdForm}
                                        setShowOther={setShowOther} />
                                </React.Fragment>
                            default:
                                return null;
                        }

                    })()}
                </Container>
                {/* container-fluid */}
            </div>
        </React.Fragment>
    )
}

export default AddPromoCode
