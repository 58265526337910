import React, { useEffect, useState } from "react"
import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Badge,
  DropdownItem,
  Row,
  Col,
  Table,
  Button,
  Input,
  Label,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import logo from "../../assets/images/logo.png"
import { get, post } from "../../helpers/api_helper"

const CancelOrder = props => {
  const [paymentMode, setPaymentMode] = useState("")

  // const openRazorPayWindow = () => {
  //     console.log(window, "wwww")
  //     var options = {
  //         "key": "rzp_test_n865XMFE3qfLTa", // Enter the Key ID generated from the Dashboard
  //         "amount": "10000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
  //         "currency": "INR",
  //         "name": "BrakeUp",
  //         "description": "BrakeUp Online Transaction",
  //         "image": logo,
  //         // "order_id": "123456",
  //         "handler":  (response) => {

  //         },
  //         "prefill": {
  //             "name": "",
  //             "email": "",
  //             "contact": ""
  //         },
  //         "notes": {
  //             "address": "BrakeUp, India"
  //         },
  //         "theme": {
  //             "color": "#F37254"
  //         }
  //     };
  //     const rzp1 = new window.Razorpay(options);
  //     rzp1.open();
  // }
  useEffect(() => {
    const {
      match: { params },
    } = props
    get("/api/v2/order/payment_mode/?order_id=" + params.id)
      .then(res => {
        // console.log(res,'HEREEEEEEEe');
        setPaymentMode(res.payment_mode)
      })
      .catch(err => {
        setPaymentMode("")
      })
  }, [])
  // console.log(paymentMode,'paymentModepaymentModepaymentMode');
  return (
    <React.Fragment>
      <div className="page-content" style={{ background: "white" }}>
        <MetaTags>
          <title>Order| Brakeup</title>
        </MetaTags>
        <Container fluid>
          {/* <Row>
                        <Col xs="6" >
                            <Input type="textarea" name="text" id="exampleText" placeholder="Reason for cancelation of order" style={{ height: "160px" }}/>
                        </Col>
                    </Row> */}
          <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
            <Col>
              <div className="mt-4 pt-3">
                <h5 className="font-size-14 mb-3">
                  Payment Received Mode(auto select)
                </h5>

                <div className="form-check mt-2">
                  <Input
                    type="checkbox"
                    value={paymentMode == "debit_credit_card" ? true : false}
                    checked={paymentMode == "debit_credit_card" ? true : false}
                    className="form-check-input"
                    id={"debit-credit-card"}
                  />
                  <Label
                    className="form-check-label"
                    htmlFor={"debit-credit-card"}
                  >
                    Debit/credit card
                  </Label>
                </div>
                <div className="form-check mt-2">
                  <Input
                    type="checkbox"
                    value={true}
                    checked={paymentMode == "upi" ? true : false}
                    className="form-check-input"
                    id={"upi"}
                  />
                  <Label className="form-check-label" htmlFor={"upi"}>
                    UPI
                  </Label>
                </div>
                <div className="form-check mt-2">
                  <Input
                    type="checkbox"
                    value={paymentMode == "netbanking" ? true : false}
                    checked={paymentMode == "netbanking" ? true : false}
                    className="form-check-input"
                    id={"net-banking"}
                  />
                  <Label className="form-check-label" htmlFor={"net-banking"}>
                    Net banking
                  </Label>
                </div>
                <div className="form-check mt-2">
                  <Input
                    type="checkbox"
                    value={paymentMode == "pay_on_delivery" ? true : false}
                    checked={paymentMode == "pay_on_delivery" ? true : false}
                    className="form-check-input"
                    id={"pay-on-delivery"}
                  />
                  <Label
                    className="form-check-label"
                    htmlFor={"pay-on-delivery"}
                  >
                    Pay on delivery
                  </Label>
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ marginBottom: "120px", float: "right" }}>
            <div className="d-flex flex-wrap gap-2">
              <Button
                type="submit"
                color="primary"
                // onClick={openRazorPayWindow}
                className=" "
              >
                Submit
              </Button>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default CancelOrder
