import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import MetaTags from "react-meta-tags"

import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap"
import Select from "react-select"
import Dropzone from "react-dropzone"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useForm, Controller, useFieldArray } from "react-hook-form"
import { CustomInput, CustomSelect } from "../Ecommerce/customInputs"
import { post, get } from "../../helpers/api_helper"
import axios from "axios"
import StarRatings from "react-star-ratings"
import { Loader } from "../../helpers/loader"
import { useParams } from "react-router-dom"
import { ErrorToast } from "../../helpers/toast"
import { useHistory } from "react-router-dom"
import { success, error } from "../../service/alerts"

const AddBrand = () => {
  const {
    handleSubmit,
    errors,
    control,
    setValue,
    watch,
    register,
    unregister,
  } = useForm({
    mode: "onChange",
  })
  const history = useHistory()
  const [selectedFiles, setselectedFiles] = useState([])
  const [fuelType, setFuelType] = useState("petrol")
  const [loader, setLoader] = useState(false)
  const [imageLoader, setImageLoader] = useState(false)
  const [showError, setShowError] = useState(false)
  const [message, setMessage] = useState("")
  const { id } = useParams()
  const getProductDetails = async id => {
    const result = await get(`/api/v2/products/add_brand/?id=${id}`)
    if (result && result.data) {
      setValue("brand", result.data[0].brand_name)
    }
  }
  useEffect(() => {
    if (id) {
      getProductDetails(id)
    }
  }, [id])
  const onSubmit = async items => {
    let values = {
      brand_name: items.brand,
      isActive: true,
    }
    setLoader(true)
    try {
      let urls = "/api/v2/products/add_brand/"
      if (id) {
        urls = "/api/v2/products/update_brand/"
        values.brand_id = id
      }
      post(urls, values)
        .then(res => {
          success("able to create brand")
          setLoader(false)
          history.push("/brands")
        })
        .catch(err => {
          setLoader(false)
          error(err?.response?.data?.message)
        })
    } catch (err) {
      setLoader(false)
    }
  }
  const cancelMake = () => {
    setValue("make", "")
    setselectedFiles([])
  }
  return (
    <React.Fragment>
      <div className="page-content">
        {showError && (
          <ErrorToast
            message={message}
            showError={showError}
            setShowError={setShowError}
          />
        )}
        <MetaTags>
          <title>Add Make | BrakeUp</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Commercial" breadcrumbItem="Add Make" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col sm="5">
                        <div className="mb-3">
                          <Label htmlFor="productname">Brand Name</Label>
                          <Controller
                            as={<CustomInput />}
                            defaultValue={null}
                            name="brand"
                            onChange={([e]) => {
                              return { value: e }
                            }}
                            control={control}
                            rules={{ required: true }}
                          />
                          {errors.brand && (
                            <span style={{ color: "red" }}>
                              Brand name is required.
                            </span>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      {loader && <Loader />}
                      <Button
                        color="secondary"
                        className="btn"
                        onClick={() => cancelMake()}
                      >
                        Cancel
                      </Button>
                      <Button type="submit" color="primary" className="btn ">
                        Submit
                      </Button>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddBrand
