import React, { useState } from "react"
import { useForm, Controller, useFieldArray } from "react-hook-form"
import { CustomInput, CustomSelect } from "./customInputs"
import { Button, Label, Row, Col } from "reactstrap"
import { submit } from "redux-form"
import { useEffect } from "react"
import { post, get } from "../../helpers/api_helper"

const AddVehicle = props => {
  const { vehicleDetails, editVehicle, seteditVehicle, vehicles, setVehicles } =
    props

  const {
    handleSubmit: handleSubmit2,
    errors: errors2,
    control: control2,
    setValue: setValue2,
    register,
    watch,
    reset: reset2,
    clearErrors,
  } = useForm({
    mode: "onBlur",
  })

  useEffect(() => {
    setTimeout(() => {
      if (Object.keys(editVehicle).length > 0) {
        setValue2(
          "compatible_vehicle_make_id",
          editVehicle?.compatible_vehicle_make_id
        )
        setValue2(
          "compatible_vehicle_model_id",
          editVehicle?.compatible_vehicle_model_id
        )
        setValue2(
          "compatible_vehicle_version",
          editVehicle?.compatible_vehicle_version
        )
        setValue2(
          "compatible_vehicle_fuel_type",
          editVehicle?.compatible_vehicle_fuel_type
        )
        setValue2(
          "compatible_vehicle_from_year",
          editVehicle?.compatible_vehicle_from_year
        )
        setValue2("isPresent", editVehicle?.isPresent)
        setValue2(
          "compatible_vehicle_to_year",
          editVehicle?.compatible_vehicle_to_year
        )

        getModel(editVehicle?.compatible_vehicle_make_id)
        getVersion(editVehicle?.compatible_vehicle_model_id)
        // console.log(editVehicle, "HEREEEEEEEEEEEEEEEEee")
      }
    }, 200)
    clearErrors()
  }, [editVehicle])

  const [make, setMake] = useState([])
  const [model, setModel] = useState([])
  const [version, setVersion] = useState([])

  const onSubmit = values => {
    if (values.hasOwnProperty("isPresent") == true) {
      values.isPresent = Boolean(values.isPresent)
      if (values.isPresent == true) {
        values.compatible_vehicle_to_year = null
      }
      values.compatible_vehicle_version =
        values?.compatible_vehicle_version ?? null
    }
    vehicleDetails(values)
  }

  const getMake = async () => {
    const result = await get("/api/v2/vehiclemake/?is_version=true")
    if (result.data) {
      let inputs = []
      result.data &&
        result.data.map(item => {
          if (typeof item.make === "string") {
            inputs.push({
              label: item.make,
              value: item._id["$oid"],
            })
          }
        })
      setMake(inputs)
    } else {
      setMake([])
    }
  }
  const getModel = async id => {
    const result = await get(`/api/v2/vehiclemodel/?id=${id.value}`)
    if (result.data) {
      let inputs = []
      result.data &&
        result.data.map(item => {
          inputs.push({
            label: item.model,
            value: item._id["$oid"],
          })
        })
      setModel(inputs)
    } else {
      setModel([])
    }
  }

  const getVersion = async id => {
    const result = await get(`/api/v2/make_details/?id=${id.value}`)
    if (result) {
      let inputs = []
      result?.version &&
        result?.version.map(item => {
          if (!item.includes("Version")) {
            inputs.push({
              label: item,
              value: item,
            })
          }
        })
      setVersion(inputs)
    } else {
      setVersion([])
    }
  }
  useEffect(() => {
    getMake()
  }, [])

  const resetting = () => {
    reset2({
      compatible_vehicle_make_id: null,
      compatible_vehicle_model_id: null,
      compatible_vehicle_version: null,
      compatible_vehicle_fuel_type: null,
      compatible_vehicle_from_year: null,
      compatible_vehicle_to_year: null,
    })
  }

  const presentItem = watch("isPresent", "false")
  return (
    <form>
      <Row>
        <Row>
          <h4>
            Compatible vehicles
            <Button
              color="light"
              className="p-1 ms-5"
              onClick={() => {
                resetting(), seteditVehicle([])
              }}
            >
              Clear
            </Button>
          </h4>
        </Row>
        <Row>
          <Col lg="4">
            <div className="mb-3">
              <Label htmlFor="productname">Make</Label>
              <Controller
                as={<CustomSelect />}
                defaultValue={null}
                name={"compatible_vehicle_make_id"}
                options={make}
                onChange={([e]) => {
                  return { value: e }
                }}
                control={control2}
                rules={{ required: true }}
                setSelectedPackage={getModel}
              />
              {errors2.compatible_vehicle_make_id && (
                <span style={{ color: "red" }}>Make is required.</span>
              )}
            </div>
          </Col>
          <Col lg="4">
            <div className="mb-3">
              <Label htmlFor="productname">Model</Label>
              <Controller
                as={<CustomSelect />}
                defaultValue={null}
                name={"compatible_vehicle_model_id"}
                options={model}
                onChange={([e]) => {
                  return { value: e }
                }}
                control={control2}
                rules={{ required: true }}
                setSelectedPackage={getVersion}
              />
              {errors2.compatible_vehicle_model_id && (
                <span style={{ color: "red" }}>Model is required.</span>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="4">
            <div className="mb-3">
              <Label htmlFor="productname">Version</Label>
              <Controller
                as={<CustomSelect />}
                defaultValue={null}
                name="compatible_vehicle_version"
                options={version}
                onChange={([e]) => {
                  // console.log([e])
                  return { value: e }
                }}
                control={control2}
                rules={{ required: false }}
              />
              {errors2.compatible_vehicle_version && (
                <span style={{ color: "red" }}>Version is required.</span>
              )}
            </div>
          </Col>
          <Col lg="4">
            <div className="mb-3">
              <Label htmlFor="horizontal-firstname-Input">Fuel Type</Label>
              <Col sm={12}>
                <div className="form-floating mb-3">
                  <Controller
                    as={<CustomSelect />}
                    defaultValue={null}
                    name={"compatible_vehicle_fuel_type"}
                    options={[
                      { value: "Petrol", label: "Petrol" },
                      { value: "Diesel", label: "Diesel" },
                      { value: "Electric", label: "Electric" },
                    ]}
                    onChange={([e]) => {
                      return { value: e }
                    }}
                    control={control2}
                    rules={{ required: true }}
                  />
                  {errors2.compatible_vehicle_fuel_type && (
                    <span style={{ color: "red" }}>Fuel Type is required.</span>
                  )}
                </div>
              </Col>
            </div>
          </Col>
        </Row>
        <Col lg="2">
          <div className="mb-3">
            <Label htmlFor="productname">From year</Label>
            <Controller
              as={<CustomInput />}
              defaultValue={null}
              name="compatible_vehicle_from_year"
              onChange={([e]) => {
                return { value: e }
              }}
              type="number"
              control={control2}
              rules={{ required: true }}
            />
            {errors2.compatible_vehicle_from_year && (
              <span style={{ color: "red" }}>From year is required.</span>
            )}
          </div>
        </Col>
        <Col
          sm="2"
          className="d-flex align-items-end justify-content-center mb-3"
        >
          <div>
            <Label htmlFor="productname"></Label>
            <br />
            <input
              ref={register({ required: false })}
              name={"isPresent"}
              value={"true"}
              type="checkbox"
            />
            <span style={{ marginLeft: "5px" }}>Present</span>
          </div>
        </Col>
        {presentItem == false && (
          <Col lg="2">
            <div className="mb-3">
              <Label htmlFor="productname"> To year</Label>
              <Controller
                as={<CustomInput />}
                defaultValue={null}
                name="compatible_vehicle_to_year"
                onChange={([e]) => {
                  return { value: e }
                }}
                type="number"
                control={control2}
                rules={{ required: true }}
              />
              {errors2.compatible_vehicle_to_year && (
                <span style={{ color: "red" }}>To year is required.</span>
              )}
            </div>
          </Col>
        )}
        <Col lg="3" className="align-self-center mt-3 ms-3">
          <Button color="primary" onClick={handleSubmit2(d => onSubmit(d))}>
            Add
          </Button>
        </Col>
      </Row>
    </form>
  )
}
export default AddVehicle
