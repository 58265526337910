import React, { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import MetaTags from "react-meta-tags"

import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  TabContent,
  Table,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap"
import Select from "react-select"
import Dropzone from "react-dropzone"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useForm, Controller, useFieldArray } from "react-hook-form"
import { CustomInput, CustomSelect } from "./customInputs"
import { post, get } from "../../helpers/api_helper"
import axios from "axios"
import StarRatings from "react-star-ratings"
import AddVehicle from "./AddVehicleDetails"
import { Loader } from "../../helpers/loader"
import { useParams } from "react-router-dom"
import { ErrorToast } from "../../helpers/toast"
import { useHistory } from "react-router-dom"
import { AiFillDelete } from "react-icons/ai"
import { remove } from "lodash"
import { error } from "toastr"
import { CopyOutlined, DeleteOutlined } from "@ant-design/icons"

import { API_URL } from "../../helpers/api_helper"

const EcommerceAddProduct = () => {
  let defaultValues = {
    vehicle: [
      {
        compatible_vehicle_make: "",
        compatible_vehicle_model: "",
        compatible_vehicle_from_year: "",
        compatible_vehicle_to_year: "",
      },
    ],
  }
  const {
    handleSubmit,
    errors,
    control,
    setValue,
    watch,
    register,
    unregister,
  } = useForm({
    mode: "onChange",
  })
  const history = useHistory()
  const [selectedFiles, setselectedFiles] = useState([])
  const [productType, setProductType] = useState([])
  const [loader, setLoader] = useState(false)
  const [imageLoader, setImageLoader] = useState(false)
  const [starValues, setStarValues] = useState({
    performance: 4,
    dust: 3,
    squeal: 3,
    life: 4,
  })
  const [showError, setShowError] = useState(false)
  const [message, setMessage] = useState("")
  const [vehicles, setVehicles] = useState([])
  const [highlights, setHighlights] = useState([])
  const [brandList, setBrandList] = useState([])
  // const [totalStock, settotalStock] = useState(null)
  const categoryOptions = [
    { value: "Brake Rotor", label: "Brake Rotor" },
    { value: "Brake Pad", label: "Brake Pad" },
    { value: "Brake Fluid", label: "Brake Fluid" },
    { value: "Other", label: "Other Brake Products" },
  ]

  const [editVehicle, seteditVehicle] = useState([])

  const getSubCategory = value => {
    let result = []
    if (value.value == "Brake Rotor") {
      result = [
        { value: "Solid", label: "SOLID" },
        { value: "Drilled", label: "DRILLED" },
        { value: "Slotted", label: "SLOTTED" },
      ]
    } else if (value.value == "Brake Fluid") {
      result = [
        { value: "Dot4", label: "DOT 4" },
        { value: "Dot5", label: "DOT 5" },
        { value: "Dot5.1", label: "DOT 5.1" },
      ]
    }
    return result
  }
  const axleOptions = [
    { value: "Front", label: "FRONT" },
    { value: "Rear", label: "REAR" },
  ]
  const gstOptions = [
    { value: "5", label: "5%" },
    { value: "12", label: "12%" },
    { value: "18", label: "18%" },
    { value: "28", label: "28%" },
  ]
  const { id } = useParams()
  const getProductDetails = async id => {
    const result = await get(`/api/v2/product/productdetail/?id=${id}`)

    console.log(result, "productdetail/?id")
    setselectedFiles(result.image)
    setValue("prod_name", result.prod_name)
    setValue("brand", { value: result.brand_id, label: result.brand })
    const catgory = categoryOptions.filter(items => {
      return items.value == result.category
    })
    if (catgory && catgory.length > 0) {
      setValue("category", catgory[0])
      const obj = getSubCategory(catgory[0])
      setProductType(obj)
      if (obj && obj.length > 0) {
        const subCatgory = obj.filter(items => {
          return items.value == result.type
        })
        if (subCatgory) {
          setValue("type", subCatgory[0])
        }
      }
    }
    const axleDls = axleOptions.filter(items => {
      return items.value == result.axle
    })
    if (axleDls && axleDls.length > 0) {
      setValue("axle", axleDls[0])
    }

    setValue("wear_indicator", {
      value: result.wear_indicator,
      label: result.wear_indicator,
    })
    setValue("package_length", result.package_length)
    setValue("package_breadth", result.package_breadth)
    setValue("package_height", result.package_height)
    setValue("product_weight", result.product_weight)
    setValue("product_width", result.product_width)
    setValue("product_thickness", result.product_thickness)
    setValue("product_diameter", result.product_diameter)
    setValue("product_height", result.product_height)
    setValue("no_of_holes", result.no_of_holes)
    setValue("centering", result.centering)
    setValue("min_thickness", result.min_thickness)
    setValue("tightening_torque", result.tightening_torque)

    setValue("unit_per_box", result.unit_per_box)
    setValue("seller_part_no", result.seller_part_no)
    // setValue("brakeup_part_no", result.brakeup_part_no);

    setValue("oem_part_no", result.oem_part_no)
    setValue("tags", result.tags)
    setValue("mrp", result.mrp)
    setValue("total_stock", result.total_stock)
    setValue("dimension", result.dimension)
    setValue("weight", result.weight)
    setValue("tags", result.tags)
    setValue("mrp", result.MRP)
    setValue("selling_price", result.Selling_Price)
    setValue("brand_part_no", result.brand_part_no)
    setValue("description", result.description)

    // settotalStock(result.total_stock)

    setHighlights(result.highlights ? result.highlights : [])
    const gstDts = gstOptions.filter(items => {
      return items.value == result.GST
    })
    if (gstDts && gstDts.length > 0) {
      setValue("GST", gstDts[0])
    }
    result.compatible_vehicles &&
      result.compatible_vehicles.map(items => {
        items.compatible_vehicle_model_id = {
          label: items.compatible_vehicle_model,
          value: items.compatible_vehicle_model_id,
        }
        items.compatible_vehicle_make_id = {
          label: items.compatible_vehicle_make,
          value: items.compatible_vehicle_make_id,
        }
        items.compatible_vehicle_version = {
          label: items.compatible_vehicle_version,
          value: items.compatible_vehicle_version,
        }
        items.compatible_vehicle_fuel_type = {
          label: items.compatible_vehicle_fuel_type,
          value: items.compatible_vehicle_fuel_type,
        }
      })
    setVehicles(
      result.compatible_vehicles ? [...result.compatible_vehicles] : []
    )
    setStarValues({
      performance: result.performance ? result.performance : 0,
      life: result.life ? result.life : 0,
      dust: result.dust ? result.dust : 0,
      squeal: result.squeal ? result.squeal : 0,
    })
  }
  useEffect(() => {
    if (id) {
      getProductDetails(id)
    }
  }, [id])
  useEffect(() => {
    get("/api/v2/products/add_brand/")
      .then(result => {
        let brand = []
        result.data &&
          result.data.map(items => {
            brand.push({
              label: items.brand_name,
              value: items["_id"]["$oid"],
            })
          })
        setBrandList(brand)
      })
      .catch(err => {
        setBrandList([])
      })
  }, [])
  const handleAcceptedFiles = acceptedFiles => {
    let formData = new FormData()
    formData.append("name", acceptedFiles[0].name)
    formData.append("type", "product")
    formData.append("file", acceptedFiles[0])
    setImageLoader(true)
    fetch(API_URL + `/api/v2/products/uploadimage/`, {
      method: "POST",
      body: formData,
    })
      .then(response => response.json())
      .then(res => {
        let selected = [...selectedFiles]
        selected.push(`${res.url}`)
        setselectedFiles(selected)
        setImageLoader(false)
      })
  }
  const onSubmit = async items => {
    if (!selectedFiles || selectedFiles.length == 0) {
      error("Please add image")
      return
    }
    if (!vehicles || vehicles.length == 0) {
      error("Please add Vehicles")
      return
    }
    const token = JSON.parse(localStorage.getItem("adminToken"))
    let values = { ...items }
    values.GST = items.GST.value
    values.axle = items?.axle?.value
    values.wear_indicator = items?.wear_indicator?.value
    values.category = items.category.value
    values.type = items?.type?.value
    values.brand_id = items?.brand?.value
    values.brand = items?.brand?.label
    // values.total_stock = totalStock ?? 0
    values.image = [...selectedFiles]
    let vehicle = []
    vehicles &&
      vehicles.map(items => {
        // console.log(items, "vehicles.map(items =>");
        vehicle.push({
          compatible_vehicle_make_id: items.compatible_vehicle_make_id.value,
          compatible_vehicle_model_id: items.compatible_vehicle_model_id.value,
          compatible_vehicle_version:
            items.compatible_vehicle_version?.value ?? null,
          compatible_vehicle_fuel_type:
            items.compatible_vehicle_fuel_type.value,
          compatible_vehicle_from_year: items.compatible_vehicle_from_year,
          compatible_vehicle_to_year: items.compatible_vehicle_to_year,
          isPresent: items.isPresent,
        })
      })
    values.vehicle = vehicle
    values.performance = starValues.performance
    values.dust = starValues.dust
    values.squeal = starValues.squeal
    values.life = starValues.life
    values.highlights = [...highlights]
    values.user_id = token.id
    values.user_type = "Admin"
    // setLoader(true);

    try {
      let urls = "/api/v2/products/product/"
      if (id) {
        urls = "/api/v2/products/updateproduct/"
        values.prod_id = id
      }
      // console.log(values, "/api/v2/products/product/");

      post(urls, values)
        .then(res => {
          history.push("/products")
        })
        .catch(err => {
          setLoader(false)
          setShowError(true)
          setMessage(err.response.data.message)
          window.scrollTo({ top: 0, behavior: "smooth" })
        })
    } catch (err) {
      setLoader(false)
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
  }

  const onCategoryChange = value => {
    const result = getSubCategory(value)
    setProductType(result)
  }
  const changeRating = (rating, type) => {
    let values = { ...starValues }
    values[type] = rating
    setStarValues(values)
  }
  const vehicleDetails = values => {
    console.log(values, "vehicleDetailsvehicleDetails")
    const vehicle = [...vehicles]
    vehicle.push({ ...values })
    setVehicles(vehicle)
  }
  const GenerateHighlights = props => {
    let newValue = ""
    const onChange = e => {
      newValue = e.target.value
    }
    const onAdd = () => {
      if (newValue) {
        let oldHigh = [...highlights]
        oldHigh.push(newValue)
        setHighlights(oldHigh)
      }
    }
    const remove = index => {
      let oldHigh = [...highlights]
      oldHigh.splice(index, 1)
      setHighlights(oldHigh)
    }
    return (
      <>
        <Row>
          <Col sm="6">
            <div className="mb-3">
              <Label htmlFor="manufacturername">Highlights</Label>
              <Input
                name={"highlights"}
                className="form-control"
                onChange={e => onChange(e)}
              />
            </div>
          </Col>
          <Col lg="3" style={{ marginTop: "26px" }}>
            <Button color="primary" onClick={d => onAdd()}>
              Add
            </Button>
          </Col>
        </Row>
        <Row>
          {highlights &&
            highlights.map((label, index) => {
              return (
                <Col sm="6" key={index}>
                  <span>{label}</span>
                  <AiFillDelete onClick={() => remove(index)} />
                </Col>
              )
            })}
        </Row>
      </>
    )
  }
  const removePics = index => {
    let oldFiels = [...selectedFiles]
    oldFiels.splice(index, 1)
    setselectedFiles(oldFiels)
  }

  //scroll to Compatible vehicles / Addvehicles

  const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop)

  const myRef = useRef(null)
  // const executeScroll = () => scrollToRef(myRef)
  const executeScroll = () => myRef.current.scrollIntoView()

  return (
    <React.Fragment>
      <div className="page-content">
        {showError && (
          <ErrorToast
            message={message}
            showError={showError}
            setShowError={setShowError}
          />
        )}
        <MetaTags>
          <title>Add Product | BrakeUp</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Commercial" breadcrumbItem="Add Product" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Basic Information</CardTitle>
                  <CardSubtitle className="mb-4">
                    Fill all information below
                  </CardSubtitle>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col sm="4">
                        <div className="mb-3">
                          <Label htmlFor="productname">Product Name</Label>
                          <Controller
                            as={<CustomInput />}
                            defaultValue={null}
                            name="prod_name"
                            onChange={([e]) => {
                              return { value: e }
                            }}
                            control={control}
                            rules={{ required: true }}
                          />
                          {errors.prod_name && (
                            <span style={{ color: "red" }}>
                              Product name is required.
                            </span>
                          )}
                        </div>
                        <div className="mb-3">
                          <Label className="control-label">
                            Product Category
                          </Label>
                          <Controller
                            as={<CustomSelect />}
                            defaultValue={null}
                            name={"category"}
                            options={categoryOptions}
                            onChange={([e]) => {
                              return { value: e }
                            }}
                            control={control}
                            rules={{ required: true }}
                            setSelectedPackage={onCategoryChange}
                          />
                          {errors.category && (
                            <span style={{ color: "red" }}>
                              Category is required.
                            </span>
                          )}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="manufacturername">Brand Name</Label>

                          <Controller
                            as={<CustomSelect />}
                            defaultValue={null}
                            name={"brand"}
                            options={brandList}
                            onChange={([e]) => {
                              return { value: e }
                            }}
                            control={control}
                            rules={{ required: true }}
                          />
                          {errors.brand && (
                            <span style={{ color: "red" }}>
                              Brand name is required.
                            </span>
                          )}
                        </div>

                        <div className="mb-3">
                          <Label className="control-label">Type</Label>
                          <Controller
                            as={<CustomSelect />}
                            defaultValue={null}
                            name={"type"}
                            options={productType}
                            onChange={([e]) => {
                              return { value: e }
                            }}
                            control={control}
                            rules={{ required: false }}
                          />
                          {errors.type && (
                            <span style={{ color: "red" }}>
                              Type is required.
                            </span>
                          )}
                        </div>
                        <div className="mb-3">
                          <Label className="control-label">Axle</Label>
                          <Controller
                            as={<CustomSelect />}
                            defaultValue={null}
                            name={"axle"}
                            options={axleOptions}
                            onChange={([e]) => {
                              return { value: e }
                            }}
                            control={control}
                            rules={{ required: false }}
                          />
                          {errors.axle && (
                            <span style={{ color: "red" }}>
                              Axle is required.
                            </span>
                          )}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="productname">
                            Wear Indicator(Pad)
                          </Label>
                          <Controller
                            as={<CustomSelect />}
                            defaultValue={null}
                            name="wear_indicator"
                            options={[
                              { value: "on vehicle", label: "on vehicle" },
                              { value: "on product", label: "on product" },
                              {
                                value: "not available",
                                label: "not available",
                              },
                            ]}
                            onChange={([e]) => {
                              return { value: e }
                            }}
                            control={control}
                            rules={{ required: false }}
                          />
                          {errors.wear_indicator && (
                            <span style={{ color: "red" }}>
                              Wear Indicator(Pad) is required.
                            </span>
                          )}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="productname">
                            Length(Package)(CM)
                          </Label>
                          <Controller
                            as={<CustomInput />}
                            defaultValue={null}
                            name="package_length"
                            onChange={([e]) => {
                              return { value: e }
                            }}
                            type="number"
                            control={control}
                            rules={{ required: true }}
                          />
                          {errors.package_length && (
                            <span style={{ color: "red" }}>
                              Length is required.
                            </span>
                          )}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="productname">
                            Breadth(Package)(CM)
                          </Label>
                          <Controller
                            as={<CustomInput />}
                            defaultValue={null}
                            name="package_breadth"
                            onChange={([e]) => {
                              return { value: e }
                            }}
                            type="number"
                            control={control}
                            rules={{ required: true }}
                          />
                          {errors.package_breadth && (
                            <span style={{ color: "red" }}>
                              Breadth is required.
                            </span>
                          )}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="productname">
                            Height(Package)(CM)
                          </Label>
                          <Controller
                            as={<CustomInput />}
                            defaultValue={null}
                            name="package_height"
                            onChange={([e]) => {
                              return { value: e }
                            }}
                            type="number"
                            control={control}
                            rules={{ required: true }}
                          />
                          {errors.package_height && (
                            <span style={{ color: "red" }}>
                              Height is required.
                            </span>
                          )}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="productname">
                            Weight(Product)(Kg)
                          </Label>
                          <Controller
                            as={<CustomInput />}
                            defaultValue={null}
                            name="product_weight"
                            onChange={([e]) => {
                              return { value: e }
                            }}
                            type="number"
                            control={control}
                            rules={{ required: true }}
                          />
                          {errors.product_weight && (
                            <span style={{ color: "red" }}>
                              Weight is required.
                            </span>
                          )}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="productname">Width(MM)</Label>
                          <Controller
                            as={<CustomInput />}
                            defaultValue={null}
                            name="product_width"
                            onChange={([e]) => {
                              return { value: e }
                            }}
                            type="number"
                            control={control}
                            rules={{ required: false }}
                          />
                          {errors.product_width && (
                            <span style={{ color: "red" }}>
                              Width is required.
                            </span>
                          )}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="productname">Thickness(TH)(MM)</Label>
                          <Controller
                            as={<CustomInput />}
                            defaultValue={null}
                            name="product_thickness"
                            onChange={([e]) => {
                              return { value: e }
                            }}
                            type="number"
                            control={control}
                            rules={{ required: false }}
                          />
                          {errors.product_thickness && (
                            <span style={{ color: "red" }}>
                              Thickness is required.
                            </span>
                          )}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="productname">Diameter(MM)</Label>
                          <Controller
                            as={<CustomInput />}
                            defaultValue={null}
                            name="product_diameter"
                            onChange={([e]) => {
                              return { value: e }
                            }}
                            type="number"
                            control={control}
                            rules={{ required: false }}
                          />
                          {errors.product_diameter && (
                            <span style={{ color: "red" }}>
                              Diameter is required.
                            </span>
                          )}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="productname">
                            Heigth of Product(H)(MM)
                          </Label>
                          <Controller
                            as={<CustomInput />}
                            defaultValue={null}
                            name="product_height"
                            onChange={([e]) => {
                              return { value: e }
                            }}
                            type="number"
                            control={control}
                            rules={{ required: false }}
                          />
                          {errors.product_height && (
                            <span style={{ color: "red" }}>
                              Heigth of Product is required.
                            </span>
                          )}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="productname">No of Holes</Label>
                          <Controller
                            as={<CustomInput />}
                            defaultValue={null}
                            name="no_of_holes"
                            onChange={([e]) => {
                              return { value: e }
                            }}
                            type="number"
                            control={control}
                            rules={{ required: false }}
                          />
                          {errors.no_of_holes && (
                            <span style={{ color: "red" }}>
                              No of Holes is required.
                            </span>
                          )}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="productname">Centering(B)(MM)</Label>
                          <Controller
                            as={<CustomInput />}
                            defaultValue={null}
                            name="centering"
                            onChange={([e]) => {
                              return { value: e }
                            }}
                            type="number"
                            control={control}
                            rules={{ required: false }}
                          />
                          {errors.centering && (
                            <span style={{ color: "red" }}>
                              Centering is required.
                            </span>
                          )}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="productname">Min Thickness(MM)</Label>
                          <Controller
                            as={<CustomInput />}
                            defaultValue={null}
                            name="min_thickness"
                            onChange={([e]) => {
                              return { value: e }
                            }}
                            type="number"
                            control={control}
                            rules={{ required: false }}
                          />
                          {errors.min_thickness && (
                            <span style={{ color: "red" }}>
                              Min Thickness is required.
                            </span>
                          )}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="productname">
                            TIGHTENING TORQUE(NM)
                          </Label>
                          <Controller
                            as={<CustomInput />}
                            defaultValue={null}
                            name="tightening_torque"
                            onChange={([e]) => {
                              return { value: e }
                            }}
                            type="number"
                            control={control}
                            rules={{ required: false }}
                          />
                          {errors.tightening_torque && (
                            <span style={{ color: "red" }}>
                              Tightening Torque is required.
                            </span>
                          )}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="productname">Unit per Box</Label>
                          <Controller
                            as={<CustomInput />}
                            defaultValue={1}
                            name="unit_per_box"
                            onChange={([e]) => {
                              return { value: e }
                            }}
                            type="number"
                            control={control}
                            rules={{ required: false }}
                          />
                          {errors.unit_per_box && (
                            <span style={{ color: "red" }}>
                              Unit per Box is required.
                            </span>
                          )}
                        </div>
                      </Col>
                      <Col sm="8">
                        <Row>
                          {selectedFiles.map((f, i) => {
                            return (
                              <Col
                                sm={3}
                                style={{
                                  border: "1px dashed rgb(96, 224, 255)",
                                  textAlign: "right",
                                  height: "160px",
                                  width: "auto",
                                }}
                              >
                                <AiFillDelete
                                  onClick={() => removePics(i)}
                                  style={{ cursor: "pointer" }}
                                />
                                <img
                                  data-dz-thumbnail=""
                                  className="avatar-sm rounded bg-light"
                                  style={{
                                    marginLeft: "10px",
                                    width: "100%",
                                    height: "83%",
                                  }}
                                  alt={"image"}
                                  src={
                                    f.startsWith("https://") == true
                                      ? f
                                      : "https://" + f
                                  }
                                  key={i}
                                />
                              </Col>
                            )
                          })}
                          <Col sm="3">
                            <div className="mb-3">
                              <Dropzone
                                onDrop={acceptedFiles => {
                                  handleAcceptedFiles(acceptedFiles)
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div
                                    {...getRootProps()}
                                    style={{
                                      width: "auto",
                                      height: "160px",
                                      border: "1px dashed #60e0ff",
                                      textAlign: "center",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <input {...getInputProps()} />
                                    {imageLoader && <Loader />}
                                    <h4 style={{ marginTop: "64px" }}>
                                      Images
                                    </h4>
                                  </div>
                                )}
                              </Dropzone>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="4">
                            <div className="mb-3">
                              <Label htmlFor="manufacturername">
                                Seller part number
                              </Label>
                              <Controller
                                as={<CustomInput />}
                                defaultValue={null}
                                name="seller_part_no"
                                onChange={([e]) => {
                                  return { value: e }
                                }}
                                control={control}
                                rules={{ required: true }}
                              />
                              {errors.seller_part_no && (
                                <span style={{ color: "red" }}>
                                  Seller part number is required.
                                </span>
                              )}
                            </div>
                            {/* <div className="mb-3">
                              <Label htmlFor="manufacturername">
                                Break up part number
                              </Label>
                              <Controller
                                as={<CustomInput />}
                                defaultValue={"0"}
                                name="brakeup_part_no"
                                onChange={([e]) => {
                                  return { value: e }
                                }}
                                control={control}
                                rules={{ required: true }}
                              />
                              {errors.brakeup_part_no && (
                                <span style={{ color: "red" }}>
                                  Break up part number is required.
                                </span>
                              )}
                            </div> */}
                            <div className="mb-3">
                              <Label htmlFor="manufacturername">
                                OEM part number
                              </Label>
                              <Controller
                                as={<CustomInput />}
                                defaultValue={null}
                                name="oem_part_no"
                                onChange={([e]) => {
                                  return { value: e }
                                }}
                                control={control}
                                rules={{ required: false }}
                              />
                              {errors.oem_part_no && (
                                <span style={{ color: "red" }}>
                                  OEM part number is required.
                                </span>
                              )}
                            </div>
                            <div className="mb-3">
                              <Label htmlFor="manufacturername">Tags</Label>
                              <Controller
                                as={<CustomInput />}
                                defaultValue={null}
                                name="tags"
                                onChange={([e]) => {
                                  return { value: e }
                                }}
                                control={control}
                                rules={{ required: false }}
                              />
                              {errors.tags && (
                                <span style={{ color: "red" }}>
                                  Tags is required.
                                </span>
                              )}
                            </div>
                          </Col>
                          <Col sm="4">
                            <div className="mb-3">
                              <Label htmlFor="manufacturername">MRP</Label>
                              <Controller
                                as={<CustomInput />}
                                defaultValue={null}
                                name="mrp"
                                onChange={([e]) => {
                                  return { value: e }
                                }}
                                type="number"
                                control={control}
                                rules={{ required: true }}
                              />
                              {errors.mrp && (
                                <span style={{ color: "red" }}>
                                  MRP is required.
                                </span>
                              )}
                            </div>
                            <div className="mb-3">
                              <Label className="control-label">GST</Label>
                              <Controller
                                as={<CustomSelect />}
                                defaultValue={null}
                                name={"GST"}
                                options={gstOptions}
                                onChange={([e]) => {
                                  return { value: e }
                                }}
                                control={control}
                                rules={{ required: true }}
                              />
                              {errors.axle && (
                                <span style={{ color: "red" }}>
                                  GST is required.
                                </span>
                              )}
                            </div>
                            <div className="mb-3">
                              <Label htmlFor="manufacturername">Stocks</Label>
                              <Controller
                                as={<CustomInput />}
                                defaultValue={"0"}
                                name="total_stock"
                                onChange={([e]) => {
                                  return { value: e }
                                }}
                                type="number"
                                control={control}
                                rules={{ required: false }}
                              />
                              {errors.total_stock && (
                                <span style={{ color: "red" }}>
                                  Stocks is required.
                                </span>
                              )}
                            </div>
                          </Col>
                          <Col sm="4">
                            <Row style={{ marginTop: "23px" }}>
                              <Col sm="12">
                                <div className="mb-3">
                                  <div className="text-muted">
                                    <div className="float-start">
                                      <p className="text-muted">Performance</p>
                                    </div>
                                    <div style={{ float: "right" }}>
                                      <StarRatings
                                        rating={starValues.performance}
                                        starRatedColor="#F1B44C"
                                        starEmptyColor="#2D363F"
                                        numberOfStars={5}
                                        name="rating"
                                        starDimension="14px"
                                        starSpacing="3px"
                                        isSelectable={true}
                                        changeRating={rating => {
                                          changeRating(rating, "performance")
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm="12">
                                <div className="mb-3">
                                  <div className="text-muted">
                                    <p className="text-muted float-start ">
                                      Dust
                                    </p>
                                    <div style={{ float: "right" }}>
                                      <StarRatings
                                        rating={starValues.dust}
                                        starRatedColor="#F1B44C"
                                        starEmptyColor="#2D363F"
                                        numberOfStars={5}
                                        name="rating"
                                        starDimension="14px"
                                        starSpacing="3px"
                                        isSelectable={true}
                                        changeRating={rating => {
                                          changeRating(rating, "dust")
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm="12">
                                <div className="mb-3">
                                  <p className="text-muted float-start">
                                    squeal
                                  </p>
                                  <div className="text-muted">
                                    <div style={{ float: "right" }}>
                                      <StarRatings
                                        rating={starValues.squeal}
                                        starRatedColor="#F1B44C"
                                        starEmptyColor="#2D363F"
                                        numberOfStars={5}
                                        name="rating"
                                        starDimension="14px"
                                        starSpacing="3px"
                                        isSelectable={true}
                                        changeRating={rating => {
                                          changeRating(rating, "squeal")
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm="12">
                                <div className="mb-3">
                                  <p className="text-muted float-start">Life</p>
                                  <div className="text-muted">
                                    <div style={{ float: "right" }}>
                                      <StarRatings
                                        rating={starValues.life}
                                        starRatedColor="#F1B44C"
                                        starEmptyColor="#2D363F"
                                        numberOfStars={5}
                                        name="rating"
                                        starDimension="14px"
                                        starSpacing="3px"
                                        isSelectable={true}
                                        changeRating={rating => {
                                          changeRating(rating, "life")
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="6">
                            <div className="mb-3" ref={myRef}>
                              <Label htmlFor="manufacturername">
                                Description
                              </Label>
                              <Controller
                                as={<CustomInput />}
                                defaultValue={null}
                                name="description"
                                type="textarea"
                                onChange={([e]) => {
                                  return { value: e }
                                }}
                                control={control}
                                rules={{ required: false }}
                              />
                              {errors.description && (
                                <span style={{ color: "red" }}>
                                  Description is required.
                                </span>
                              )}
                            </div>
                          </Col>
                          <Col sm="6">
                            <GenerateHighlights />
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="12">
                            <AddVehicle
                              vehicleDetails={vehicleDetails}
                              editVehicle={editVehicle}
                              seteditVehicle={seteditVehicle}
                            />
                            <div className="mt-5">
                              <div className="table-responsive">
                                <Table className="table mb-0 table-bordered">
                                  <tbody>
                                    <tr key="header">
                                      <th
                                        scope="row"
                                        style={{ minWidth: "100px" }}
                                        className={"text-capitalize"}
                                      >
                                        Make
                                      </th>
                                      <th>Model</th>
                                      <th>Version</th>
                                      <th>Fuel</th>
                                      <th>From Year</th>
                                      <th>To Year</th>
                                      <th></th>
                                      <th></th>
                                    </tr>
                                    {vehicles &&
                                      vehicles.map((items, i) => (
                                        <tr key={i}>
                                          <td className="text-capitalize">
                                            {
                                              items.compatible_vehicle_make_id
                                                ?.label
                                            }
                                          </td>
                                          <td>
                                            {
                                              items.compatible_vehicle_model_id
                                                ?.label
                                            }
                                          </td>
                                          <td>
                                            {items.compatible_vehicle_version
                                              ?.label ?? null}
                                          </td>
                                          <td>
                                            {
                                              items.compatible_vehicle_fuel_type
                                                ?.label
                                            }
                                          </td>
                                          <td>
                                            {items.compatible_vehicle_from_year}
                                          </td>
                                          <td>
                                            {items.isPresent
                                              ? new Date().getFullYear()
                                              : items.compatible_vehicle_to_year}
                                          </td>
                                          <td
                                            style={{
                                              cursor: "pointer",
                                              textAlign: "center",
                                            }}
                                            onClick={() => {
                                              let values = [...vehicles]
                                              values.splice(i, 1)
                                              setVehicles(values)
                                            }}
                                          >
                                            <DeleteOutlined
                                              style={{
                                                color: "red",
                                              }}
                                            />
                                          </td>
                                          <td
                                            style={{
                                              textAlign: "center",
                                            }}
                                            onClick={() => {
                                              seteditVehicle(items)
                                              executeScroll()
                                            }}
                                          >
                                            <CopyOutlined
                                              style={{
                                                color: "green",
                                              }}
                                              id="CopyToEdit"
                                            />
                                            <UncontrolledTooltip
                                              placement="top"
                                              target="CopyToEdit"
                                            >
                                              Copy to Compatible vehicles
                                            </UncontrolledTooltip>
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <div className="d-flex flex-wrap gap-2">
                      {loader && <Loader />}
                      <Button type="submit" color="primary" className="btn ">
                        Save
                      </Button>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EcommerceAddProduct
