import React, { useState } from "react"
import {
  Card,
  Col,
  Row,
  CardBody,
  Label,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import { post } from "../../helpers/api_helper"
import moment from "moment"
import { useHistory } from "react-router-dom"
import { success, error } from "../../service/alerts"

export const DisplayDetails = props => {
  const [modal, setModal] = useState(false)
  const history = useHistory()
  const toggle = () => {
    setModal(!modal)
    history.push("/promocode-list")
  }

  console.log(props, "DisplayDetailsDisplayDetails")

  const SuccessModal = () => {
    return (
      <div style={{ margin: 50 }}>
        {/* <Button color="danger" onClick={()=> toggle}>Toggle</Button> */}
        <Modal funk={true} isOpen={modal} toggle={toggle}>
          <ModalHeader onClick={() => toggle()}>
            <h2 style={{ color: "green" }}>PROMO CODE</h2>
          </ModalHeader>
          <ModalBody>
            <h4>
              You are able to generate the promo code also send details to{" "}
              {props?.firstForm?.user_id}
            </h4>
          </ModalBody>
          <ModalFooter>
            <Button size={"lg"} color="success" onClick={() => toggle()}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
  const saveData = () => {
    let outs = {
      choice_of_phrase: props?.firstForm?.choice_of_phrase,
      max_cart_value: props?.firstForm?.max_cart_value,
      max_discount: props?.firstForm?.max_discount,
      min_cart_value: props?.firstForm?.min_cart_value,
      min_product_value: props?.firstForm?.min_product_value,
      no_of_use: props?.firstForm?.no_of_use,
      rate: props?.firstForm?.rate?.value,
      type: props?.firstForm?.type?.value,
      promo_type: props?.firstForm?.promo_type?.value,
      shipping_charge: props?.firstForm?.shipping_charge,
      user_id: props?.firstForm?.user_id,
      validity_frm: props?.firstForm?.validity_frm,
      validity_to: props?.firstForm?.validity_to,
      value: props?.firstForm?.value,
      applicable_products: [...props?.secondForm?.applicable_products],
      excluded_products: [...props?.thirdForm?.applicable_products],
    }
    post("/api/v2/promocode/generate_promocode/", outs)
      .then(res => {
        setModal(true)
      })
      .catch(err => {
        error(err?.response?.data.message)
      })
  }
  return (
    <Row>
      <SuccessModal />
      <Col lg={12}>
        <Card>
          <CardBody>
            <Button
              color="link"
              onClick={() => props.setShowOther(1)}
              style={{ float: "right" }}
            >
              Edit
            </Button>
            <Row>
              <h1>{props?.firstForm?.choice_of_phrase}</h1>
              <Col lg={6}>
                <div className="row mb-4">
                  <Label
                    htmlFor="horizontal-firstname-Input"
                    className="col-sm-4 col-form-label"
                  >
                    Mobile/mail id
                  </Label>
                  <Col sm={4}>
                    <Label style={{ marginTop: "9px" }}>
                      {props?.firstForm?.user_id}
                    </Label>
                  </Col>
                </div>
                <div className="row mb-4">
                  <Label
                    htmlFor="horizontal-firstname-Input"
                    className="col-sm-4 col-form-label"
                  >
                    Promo User-type
                  </Label>
                  <Col sm={4}>
                    <Label style={{ marginTop: "9px" }}>
                      {props?.firstForm?.promo_type?.label}
                    </Label>
                  </Col>
                </div>
                <div className="row mb-4">
                  <Label
                    htmlFor="horizontal-firstname-Input"
                    className="col-sm-4 col-form-label"
                  >
                    Is Shipping charge applicable
                  </Label>
                  <Col sm={4}>
                    <Label style={{ marginTop: "9px" }}>
                      {props?.firstForm?.shipping_charge.toString()}
                    </Label>
                  </Col>
                </div>
                <div className="row mb-4">
                  <Label
                    htmlFor="horizontal-email-Input"
                    className="col-sm-4 col-form-label"
                  >
                    Value
                  </Label>
                  <Col sm={3}>
                    <Label style={{ marginTop: "9px" }}>
                      {props?.firstForm?.value}
                      {"/-"} {props?.firstForm?.rate?.label}
                    </Label>
                  </Col>
                </div>
                <div className="row mb-4">
                  <Label
                    htmlFor="horizontal-password-Input"
                    className="col-sm-4 col-form-label"
                  >
                    Maxmum Discount value
                  </Label>
                  <Col sm={4}>
                    <Label style={{ marginTop: "9px" }}>
                      {props?.firstForm?.max_discount}
                    </Label>
                  </Col>
                </div>
                <div className="row mb-4">
                  <Label
                    htmlFor="horizontal-password-Input"
                    className="col-sm-4 col-form-label"
                  >
                    Validity From - To
                  </Label>
                  <Col sm={7}>
                    <Label style={{ marginTop: "9px" }}>
                      {moment(props?.firstForm?.validity_frm).format(
                        "DD/MM/YYYY"
                      )}{" "}
                      -{" "}
                      {moment(props?.firstForm?.validity_to).format(
                        "DD/MM/YYYY"
                      )}
                    </Label>
                  </Col>
                </div>
              </Col>
              <Col lg={6}>
                <div className="row mb-4">
                  <Label
                    htmlFor="horizontal-firstname-Input"
                    className="col-sm-4 col-form-label"
                  >
                    Maxmum cart value
                  </Label>
                  <Col sm={2}>
                    <Label style={{ marginTop: "9px" }}>
                      {props?.firstForm?.max_cart_value}
                    </Label>
                  </Col>
                </div>
                <div className="row mb-4">
                  <Label
                    htmlFor="horizontal-firstname-Input"
                    className="col-sm-4 col-form-label"
                  >
                    Minimum cart value
                  </Label>
                  <Col sm={4}>
                    <Label style={{ marginTop: "9px" }}>
                      {props?.firstForm?.min_cart_value}
                    </Label>
                  </Col>
                </div>
                <div className="row mb-4">
                  <Label
                    htmlFor="horizontal-firstname-Input"
                    className="col-sm-4 col-form-label"
                  >
                    Minimum product value
                  </Label>
                  <Col sm={4}>
                    <Label style={{ marginTop: "9px" }}>
                      {props?.firstForm?.min_product_value}
                    </Label>
                  </Col>
                </div>
                <div className="row mb-4">
                  <Label
                    htmlFor="horizontal-firstname-Input"
                    className="col-sm-4 col-form-label"
                  >
                    Number of Use
                  </Label>
                  <Col sm={4}>
                    <Label style={{ marginTop: "9px" }}>
                      {props?.firstForm?.no_of_use}
                    </Label>
                  </Col>
                </div>
                <div className="row mb-4">
                  <Label
                    htmlFor="horizontal-firstname-Input"
                    className="col-sm-4 col-form-label"
                  >
                    Choice of Phrase
                  </Label>
                  <Col sm={4}>
                    <Label style={{ marginTop: "9px" }}>
                      {props?.firstForm?.choice_of_phrase}
                    </Label>
                  </Col>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col lg={12}>
        <Row>
          <Col lg={6}>
            <h2>Applicable Products</h2>
            <Button
              color="link"
              onClick={() => props.setShowOther(2)}
              style={{ float: "right" }}
            >
              Edit
            </Button>
            <div className="mt-5">
              <div className="table-responsive">
                <Table className="table mb-0 table-bordered">
                  <tbody>
                    <tr key="header">
                      <th>Name</th>
                      <th>Type</th>
                    </tr>
                    {props &&
                      props.secondForm &&
                      props.secondForm.filter &&
                      props.secondForm.filter.map((items, i) => (
                        <tr key={i}>
                          <td>{items.name}</td>
                          <td>{items.type}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="mt-5">
              <div className="table-responsive">
                <Table className="table mb-0 table-bordered">
                  <tbody>
                    <tr key="header">
                      <th>Name</th>
                      <th>Category</th>
                      <th>Brand</th>
                      <th>Image</th>
                    </tr>
                    {props &&
                      props.secondForm &&
                      props.secondForm.applicable_products &&
                      props.secondForm.applicable_products
                        .slice(0, 5)
                        .map((items, i) => (
                          <tr key={i}>
                            <td>{items.prod_name}</td>
                            <td>{items.category}</td>
                            <td>{items.brand}</td>
                            <td>
                              <img
                                src={items.image ? items.image[0] : ""}
                                style={{ width: "50px" }}
                              ></img>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <h2>Excluded Products</h2>
            <Button
              color="link"
              onClick={() => props.setShowOther(3)}
              style={{ float: "right" }}
            >
              Edit
            </Button>
            <div className="mt-5">
              <div className="table-responsive">
                <Table className="table mb-0 table-bordered">
                  <tbody>
                    <tr key="header">
                      <th>Name</th>
                      <th>Type</th>
                    </tr>
                    {props &&
                      props.thirdForm &&
                      props.thirdForm.filter &&
                      props.thirdForm.filter.map((items, i) => (
                        <tr key={i}>
                          <td>{items.name}</td>
                          <td>{items.type}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="mt-5">
              <div className="table-responsive">
                <Table className="table mb-0 table-bordered">
                  <tbody>
                    <tr key="header">
                      <th>Name</th>
                      <th>Category</th>
                      <th>Brand</th>
                      <th>Image</th>
                    </tr>
                    {props &&
                      props.thirdForm &&
                      props.thirdForm.applicable_products &&
                      props.thirdForm.applicable_products
                        .slice(0, 5)
                        .map((items, i) => (
                          <tr key={i}>
                            <td>{items.prod_name}</td>
                            <td>{items.category}</td>
                            <td>{items.brand}</td>
                            <td>
                              <img
                                src={items.image ? items.image[0] : ""}
                                style={{ width: "50px" }}
                              ></img>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col lg={12} style={{ margin: "25px" }}>
        <Row>
          <Col lg={12}>
            <Button
              color="primary"
              onClick={() => saveData()}
              style={{ float: "right", marginRight: "25px" }}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
