import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Badge,
  DropdownItem,
  Row,
  Col,
  Table,
  Card,
  CardBody,
  Input,
  Form,
} from "reactstrap"
// import companies from "assets/images/users"

import { Link } from "react-router-dom"

import moment from "moment"

import avatar from "assets/images/users/avatar-1.jpg"
import avatar1 from "assets/images/users/avatar-2.jpg"
import avatar2 from "assets/images/users/avatar-3.jpg"

//import action

import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { get, post } from "../../helpers/api_helper"
import axios from "axios"
import BuyerRowDetail from "./buyerDetailRow"
import { useHistory } from "react-router-dom"
import BuyerCartDetails from "./buyerCartDetails"
import { CustomCheckbox } from "pages/Ecommerce/customInputs"
import { Controller } from "react-hook-form"
import { success, error } from "service/alerts"

const BuyerDetail = props => {
  const [orders, setOrders] = useState([])
  const [user, setUser] = useState({})
  const history = useHistory()
  const [search, setSearch] = useState("")
  const [cartItems, setCartItems] = useState([])

  const getUser = id => get(`/api/v2/user/user_details/?user_id=${id}`)
  const getOrder = id => get(`/api/v2/order/orderbyusers/?user_id=${id}`)

  useEffect(() => {
    let user_id = window.location.pathname.split("/").reverse()[0]

    getUserDetails(user_id)

    get(`/api/v2/product/cart_item_list_buyer/?user_id=${user_id}`)
      .then(res => {
        setCartItems(res.data)
      })
      .catch(error => {
        setCartItems([])
      })

    get(`/api/v2/order/buyer/?user_id=${user_id}`)
      .then(response => {
        if (response.data && response.data.length >= 1) {
          // if (Array.isArray(response.data) == true) {
          setOrders(response.data)
          // } else {
          //   setOrders(response.dat)
          // }
        }
      })
      .catch(error => {})
  }, [])

  const onClickRow = item => {
    console.log(item, "HEREEEEEEEEeee")
    history.push("/orders/" + item.order_id)
  }
  const onSearchChange = async (searchValue, filterValue) => {
    let user_id = window.location.pathname.split("/").reverse()[0]
    let url = "/api/v2/search/?q=buyer_orders&user_id=" + user_id
    if (searchValue) {
      url = `${url}&s=${searchValue}`
    }
    setSearch(searchValue)
    try {
      const result = await get(url)
      if (result.data) {
        setOrders(result.data)
      } else {
        setOrders([])
      }
    } catch {
      setOrders([])
    }
  }

  const getUserDetails = user_id => {
    get(`/api/v2/user/user_details/?user_id=${user_id}`)
      .then(response => {
        setUser(response)
      })
      .catch(error => {})
  }

  const onClickCOD = value => {
    let user_id = window.location.pathname.split("/").reverse()[0]

    let data = { user_id: user_id, is_coddisabled: value }
    post(`/api/v2/products/cod_user_update/`, data)
      .then(response => {
        if (value == true) {
          success("Buyer Details updated, COD  disabled")
        } else {
          error("Cod - enabled")
        }
        getUserDetails(user_id)
      })
      .catch(error => {
        error("sorry not able update")
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Buyer Detail | BrakeUp</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Buyer Detail")}
            breadcrumbItem={props.t("Buyer Detail")}
          />
          <br />
          <Row className="mb-3">
            <Col lg="7" sm="6">
              <Form className="">
                <div className="search-box me-2">
                  <div className="position-relative">
                    <Input
                      type="text"
                      className="form-control border-0"
                      placeholder="Search Buyer by phone number, ID"
                      onChange={e => onSearchChange(e.target.value)}
                    />
                    <i className="bx bx-search-alt search-icon" />
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Card style={{ height: "100%" }}>
                <CardBody>
                  {user && (
                    <>
                      <h3 className="text-bold mb-4">
                        {user.name || user.mobile}
                      </h3>

                      <div className="text-muted mb-1  ">Name: {user.name}</div>
                      <div className="text-muted mb-1  ">
                        Contact No: {user.mobile}
                      </div>
                      <div className="text-muted mb-1  ">
                        Email: {user.email}
                      </div>
                      <div className="d-flex">
                        <div className="text-muted mb-1  text-nowrap ">
                          Address:
                        </div>
                        {user.pincode && (
                          <div
                            className="text-muted mb-1  "
                            style={{ textTransform: "capitalize" }}
                          >
                            {user.address}, <br />
                            {user.address2}, <br />
                            {user.landmark}, {user.city},<br /> {user.state},
                            Pin-{user.pincode}
                          </div>
                        )}
                      </div>
                      {user["created_at"] && (
                        <div className="d-flex">
                          <div className="text-muted mb-1 text-nowrap  ">
                            Created at:
                          </div>
                          <div className="text-muted mb-1  ">
                            {moment(user["created_at"]["$date"]).format("LLLL")}
                          </div>
                        </div>
                      )}

                      <div className="text-muted mb-1  ">
                        Is COD Disabled:
                        <input
                          className="form-check-input p-2 ms-2"
                          // value={true}
                          checked={user.is_coddisabled}
                          name={"shipping_charge"}
                          type="checkbox"
                          onChange={e => {
                            onClickCOD(e.target.checked)
                            // console.log(e.target.checked, "eeeeeeeeeeeeee")
                          }}
                        />
                      </div>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>

            <Col md={9}>
              <Col md={12}>
                <Card>
                  <CardBody>
                    <div className="margin-left-30">
                      <h4 className="text-muted mb-2">Buyer's Cart</h4>
                      <PerfectScrollbar>
                        <Col lg="12">
                          <div className="table-responsive">
                            <Table className="project-list-table table-nowrap align-middle  table">
                              <tbody>
                                {cartItems &&
                                  cartItems.map(item => (
                                    <BuyerCartDetails
                                      {...item}
                                      // onClick={onClickRow}
                                      item={item}
                                    />
                                  ))}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </PerfectScrollbar>
                    </div>
                  </CardBody>
                  <CardBody>
                    <div className="margin-left-30">
                      <h4 className="text-muted mb-2">Order History</h4>
                      <PerfectScrollbar>
                        <Col lg="12">
                          <div className="table-responsive">
                            <Table className="project-list-table table-nowrap align-middle  table">
                              <tbody>
                                {orders &&
                                  orders.map(item => (
                                    <BuyerRowDetail
                                      {...item}
                                      color={item.is_Approved ? "green" : "red"}
                                      onClick={onClickRow}
                                      item={item}
                                    />
                                  ))}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </PerfectScrollbar>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

BuyerDetail.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(BuyerDetail)
