import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Badge,
  DropdownItem,
  Row,
  Col,
  Table,
  Button,
} from "reactstrap"
import { get, post } from "../../helpers/api_helper"
import { Loader } from "../../helpers/loader"
//import action
import TableRow from "./orderRow"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
//i18n
import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import moment from "moment"
import Select from "react-select"
import { error, success } from "toastr"

const ReplacementForm = props => {
  const history = useHistory()
  const [order, setOrder] = useState({})
  const [comment, setComment] = useState("")
  const [loader, setLoader] = useState(false)
  const [oldComment, setOldComment] = useState([])
  const [status, selectStatus] = useState({
    value: "request_placed",
    label: "Request Placed",
  })
  const [lastComment, setLastComment] = useState({})
  const optionGroup = [
    { value: "request_placed", label: "Request Placed" },
    { value: "request_compromised", label: "Request Compromised" },
    { value: "request_accepted", label: "Request Accepted" },
    { value: "request_cancelled", label: "Order Cancelled" },
  ]
  useEffect(() => {
    const {
      match: { params },
    } = props
    get(
      `/api/v2/products/replacement_order_status/?order_id=${params.id}&current_pro_id=${params.product}`
    )
      .then(res => {
        if (res.replacement_status) {
          setOldComment(res.replacement_status)
          const result = optionGroup.filter(items => {
            return items.label == res.latest_order_status.order_status
          })
          if (result) {
            selectStatus(result[0])
            setLastComment(result[0])
          }
        }
      })
      .catch(err => {})
  }, [])

  const onSelectStatus = e => {
    selectStatus(e)
  }
  const onSubmit = () => {
    const {
      match: { params },
    } = props
    if (!status) {
      error("Please select status")
      return
    }
    if (!comment) {
      error("Please add comment")
      return
    }
    const token = JSON.parse(localStorage.getItem("authUser"))
    let data = {
      user_id: token.id,
      current_order_id: params.id,
      current_pro_id: params.product,
      comment: comment,
      replacement_status: status.label,
    }
    setLoader(true)
    post("/api/v2/products/replacement_order_status/", data)
      .then(res => {
        success(`${status.label}`)
        if (status.value == "request_accepted") {
          let order = localStorage.getItem("replace_item")
          if (order) {
            order = JSON.parse(order)
            localStorage.setItem("replacement_comment", comment)
            history.push(`/orders/${order.order_id}/select-new-product`)
          } else {
            history.push("/orders")
          }
        } else if (status.value == "request_cancelled") {
          history.push("/cancel-order/" + params.id)
        } else if (status.value == "request_compromised") {
          history.push(`/orders/${params.id}`)
        } else if (status.value == "request_placed") {
          history.push("/orders?rstatus")
        }
        setLoader(false)
      })
      .catch(err => {
        error("Sorry not able to update the status")
        setLoader(false)
      })
  }
  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{ background: "white", height: 1000 }}
      >
        <MetaTags>
          <title>Replacement | BrakeUp</title>
        </MetaTags>

        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Replacement")}
            breadcrumbItem={props.t("Replacement")}
          />
          <br />
          {loader && <Loader />}
          <Row className="h-100">
            <span style={{ color: "green" }}>
              Last request:{lastComment?.label}
            </span>
            <Col lg="4">
              <div class="col-md-8 text-center h-100">
                <Select
                  onChange={e => {
                    onSelectStatus(e)
                  }}
                  options={optionGroup}
                  value={status}
                  classNamePrefix="select2-selection"
                />
              </div>
            </Col>
            <Col lg="6">
              <div class="col-md-10 text-center">
                <textarea
                  onChange={e => setComment(e.target.value)}
                  className="form-control"
                  id="commentmessage-input"
                  placeholder="Your message..."
                  rows="5"
                ></textarea>
              </div>
            </Col>
            <Col lg="2">
              <div className="d-flex flex-wrap gap-2">
                <Button
                  type="submit"
                  color="primary"
                  className="btn "
                  onClick={() => onSubmit()}
                >
                  Submit
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
        <Row>
          <Col lg="12">
            <div>
              <h4>Comments</h4>
              <Table className="table mb-0 table-bordered">
                <tbody>
                  <tr key="header">
                    <th>Order Status</th>
                    <td>Comment</td>
                    <td>Updated by</td>
                    <td>Updated Date</td>
                  </tr>
                </tbody>
                {oldComment &&
                  oldComment.map(items => {
                    return (
                      <tr>
                        <td>{items.order_status}</td>
                        <td>{items.order_status_cmnt}</td>
                        <td>{items.updated_by}</td>
                        <td>
                          {moment(new Date(items?.created_at?.$date)).format(
                            "DD MMM Y"
                          )}
                        </td>
                      </tr>
                    )
                  })}
              </Table>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

ReplacementForm.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ReplacementForm)
