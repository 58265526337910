import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Container, Row, Col, Table } from "reactstrap"
import { Link } from "react-router-dom"

import SellerRow from "./promoCodeRows"
//i18n
import { withTranslation } from "react-i18next"
import DateRangePicker from "react-bootstrap-daterangepicker"
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-daterangepicker/daterangepicker.css"
import moment from "moment"

//redux
import { useHistory } from "react-router-dom"
import { get, post } from "../../helpers/api_helper"
import { success, error } from "../../service/alerts"

const PromoCodeHistoryLists = props => {
  const [pending, setPending] = useState([])
  const history = useHistory()
  const url = `/api/v2/promocode/listpromos/?isActive=false`
  const getData = url => {
    get(url)
      .then(res => {
        if (res.data) {
          const pendingData = [...res.data]
          setPending(pendingData)
        } else {
          setPending([])
        }
      })
      .catch(err => {
        setPending([])
      })
  }
  useEffect(() => {
    getData(url)
  }, [])

  const handleCallback = (start, end, label) => {
    // console.log(start, end, label,'start, end, label')
    const from = moment(start).format("YYYY-MM-DD")
    const to = moment(end).format("YYYY-MM-DD")
    getData(url + `&fromdate=${from}&todate=${to}`)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Row style={{ marginRight: "0px", float: "right" }}>
            <Link to="/promocode-list">Back</Link>
          </Row>
          <Row style={{ marginRight: "0px" }}>
            <h2>History</h2>
          </Row>
          <Row>
            <Col lg={4}>
              <DateRangePicker onCallback={handleCallback}>
                <input type="text" className="form-control" />
              </DateRangePicker>
            </Col>
          </Row>
          <br />
          {pending && pending.length > 0 && (
            <Row>
              <Col lg="12">
                <div className="">
                  <div className="table-responsive">
                    <Table className="project-list-table table-nowrap align-middle  table">
                      <tbody>
                        <SellerRow
                          color={"grey"}
                          pending={pending}
                          isActive={false}
                        />
                        <br />
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

PromoCodeHistoryLists.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(PromoCodeHistoryLists)
