import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Row, Col, Button, Table, Form, Input } from "reactstrap"
// import companies from "assets/images/users"
import { get } from "../../helpers/api_helper"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import moment from "moment"
import ProductRow from "./productRow"

//redux

const OrderDetail = props => {
  const history = useHistory()
  const [order, setOrder] = useState([])
  const [buyerDetails, setBuyerDetails] = useState({})
  const [search, setSearch] = useState("")

  useEffect(() => {
    getBuyerDetails()
  }, [])

  const getBuyerDetails = () => {
    let order_id = window.location.pathname.split("/").reverse()[0]
    get(`/api/v2/order/orderdetails/?order_no=${order_id}`)
      .then(response => {
        setOrder(response.product_details)
        setBuyerDetails(response.buyer_details)
      })
      .catch(error => {})
  }

  const onClickRow = row => {
    history.push("/product-orders/" + row.order_id + "/" + row.prod_id)
  }
  const onSearchChange = async (searchValue, filterValue) => {
    let url = "/api/v2/search/?q=orders&order_id=" + buyerDetails.order_Id
    if (searchValue) {
      url = `${url}&s=${searchValue}`
    }
    setSearch(searchValue)
    const result = await get(url)
    if (result && Object.keys(result).length > 0) {
      setOrder(result.product_details ? result.product_details : [])
    } else {
      setOrder([])
    }
  }
  return (
    <React.Fragment>
      <div className="page-content" style={{ background: "white" }}>
        <MetaTags>
          <title>Orders Detail | BrakeUp</title>
        </MetaTags>
        {order && (
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={props.t("Order Detail")}
              breadcrumbItem={props.t("Order Detail")}
            />
            <br />
            <Row className="mb-3">
              <Col lg="7" sm="6">
                <Form className="">
                  <div className="search-box me-2">
                    <div className="position-relative">
                      <Input
                        type="text"
                        className="form-control border-0"
                        placeholder="Search by Product name, Brand, Type, Category, Seller"
                        onChange={e => onSearchChange(e.target.value)}
                      />
                      <i className="bx bx-search-alt search-icon" />
                    </div>
                  </div>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <div className="">
                  <h4>{buyerDetails.order_Id}</h4>
                  <div className="table-responsive">
                    <Table className="project-list-table table-nowrap align-middle  table">
                      <tbody>
                        {order.map((item, index) => (
                          <>
                            <ProductRow
                              {...item}
                              onClick={() => onClickRow(item)}
                              color={item.is_Approved ? "green" : "black"}
                              buyerDetails={buyerDetails}
                              index={index + 1}
                              getBuyerDetails={getBuyerDetails}
                            />
                            <br />
                          </>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>
            {/* <div className="d-flex flex-wrap gap-2" style={{marginBottom:"120px", float:"right"}}>
                      <Button
                        type="submit"
                        color="secondary"
                        className=" "
                        onClick={()=> history.push("/cancel-order")}
                      >
                        Cancel Order
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        className="btn "
                        onClick={()=> history.push("/orders")}
                      >
                        Replacement
                    </Button>
          </div> */}
          </Container>
        )}
      </div>
    </React.Fragment>
  )
}

OrderDetail.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(OrderDetail)
