import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Container, Row, Col, Table, Button } from "reactstrap"

import SellerRow from "./promoCodeRows"
//i18n
import { withTranslation } from "react-i18next"

//redux
import { useHistory } from "react-router-dom"
import { get, post } from "../../helpers/api_helper"
import { success, error, Loader } from "../../service/alerts"

const PromoCodeLists = props => {
  const [pending, setPending] = useState([])
  const [loader, setLoader] = useState(false)
  const history = useHistory()
  const url = `/api/v2/promocode/listpromos/?isActive=true`

  const getData = url => {
    setLoader(true)
    get(url)
      .then(res => {
        if (res.data) {
          const pendingData = [...res.data]
          setPending(pendingData)
          setLoader(false)
        }
      })
      .catch(err => {
        setLoader(false)
        setPending([])
      })
  }
  useEffect(() => {
    getData(url)
  }, [])
  const deleteCode = promo_id => {
    console.log(promo_id, "promo_codepromo_code")
    post("/api/v2/promocode/delete_promocode/", { promo_id: promo_id })
      .then(res => {
        getData(url)
        success("able to delete")
      })
      .catch(err => {
        error("sorry not able delete")
      })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}

          <Row style={{ float: "right", marginRight: "0px" }}>
            <Button onClick={() => history.push("/promocode-history-list")}>
              History
            </Button>
          </Row>
          <Row style={{ float: "right", marginRight: "25px" }}>
            <Button
              onClick={() => history.push("/promocode-create")}
              color="primary"
            >
              Generate new Promo Code
            </Button>
          </Row>
          <Row>
            <div className="d-flex justify-content-between">
              {pending && pending.length > 0 && <h5>Active Promo Code</h5>}
            </div>
          </Row>
          <br />
          {loader && <Loader />}
          {pending && pending.length > 0 && (
            <Row>
              <Col lg="12">
                <div className="">
                  <div className="table-responsive">
                    <Table className="project-list-table table-nowrap align-middle  table">
                      <tbody>
                        <SellerRow
                          deleteCode={deleteCode}
                          color={"grey"}
                          pending={pending}
                          isActive={true}
                        />
                        <br />
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

PromoCodeLists.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(PromoCodeLists)
