import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Badge,
  DropdownItem,
  Row,
  Col,
  Table,
  Button,
} from "reactstrap"
import { get } from "../../helpers/api_helper"
//import action
import TableRow from "./orderRow"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
//i18n
import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import moment from "moment"
import { ModalPriceDetails } from "../Ecommerce/EcommerceProducts/profileCalculator"

const ProductDetails = props => {
  const history = useHistory()
  const [order, setOrder] = useState({})

  useEffect(() => {
    let product_id = window.location.pathname.split("/").reverse()[0]
    let order_id = window.location.pathname.split("/").reverse()[1]

    get(
      `/api/v2/order/orderproductdetails/?order_id=${order_id}&prod_id=${product_id}`
    )
      .then(response => {
        // console.log(response, 'HEREEEEEEEEEEEEEEEEEEEEEE')
        setOrder(response)
      })
      .catch(error => {})
  }, [])
  const replaceProduct = () => {
    let product_id = window.location.pathname.split("/").reverse()[0]
    localStorage.setItem("replace_item", JSON.stringify(order))
    history.push(`/orders/${order.order_id}/${product_id}/replacement`)
  }
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  return (
    <React.Fragment>
      <div className="page-content" style={{ background: "white" }}>
        <MetaTags>
          <title>Orders Detail | BrakeUp</title>
        </MetaTags>
        {order && (
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={props.t("Order Detail")}
              breadcrumbItem={props.t("Order Detail")}
            />
            <br />
            <ModalPriceDetails
              mainGst={order.order_gst}
              mrp={order.mrp}
              selling_price={order.selling_price}
              toggle={toggle}
              modal={modal}
              isEdit={false}
              min_extimated_delivery_chrg={0}
            />
            <Row>
              <Col>
                <Container>
                  {order.image &&
                    order.image.map(item => (
                      <img src={item} alt="" className="avatar-xl mb-3" />
                    ))}
                  <h4>{order.prod_name}</h4>
                </Container>
              </Col>

              <Col>
                <div className="text-muted mb-1 p-2 bordered-div">
                  <h3>{order.vehicle_name}</h3>
                </div>

                <div className="text-muted mb-1 p-2 bordered-div d-flex justify-content-start">
                  {order["created_at"] && (
                    <p>
                      Date and Time <br />{" "}
                      {moment(order["created_at"]["$date"]).format("LLLL")}{" "}
                    </p>
                  )}
                  <br />
                  {/* <p>{order['created_at']['$date']}</p> */}
                </div>
                <div className="text-muted mb-1 p-2 bordered-div d-flex justify-content-start">
                  <p>
                    Order Number <br />
                    {order.order_id}
                  </p>
                </div>
                {/* <div className="text-muted mb-1 p-2 bordered-div" >
                Invoice
                </div> */}
                <div className="text-muted text-capitalize mb-1 p-2 bordered-div d-flex justify-content-start">
                  <p>
                    Buyer Address <br />
                    {order.buyer_name} <br />
                    {order.buyer_address} <br />
                    {order.buyer_address2} <br />
                    {order.landmark} <br />
                    {order.buyer_city}, {order.buyer_state},{" "}
                    {order.buyer_pincode} <br />
                    {order.buyer_phone} <br />
                  </p>
                </div>
                <div className="text-muted mb-1 p-2 bordered-div d-flex justify-content-start">
                  <p>
                    {" "}
                    Seller Address <br />
                    {order.vendor_name} <br />
                    {order.vendor_phone} <br />
                    {order.vendor_address}
                  </p>
                </div>
                <div className="text-muted mb-1 p-2 bordered-div d-flex justify-content-start">
                  <p>
                    Status <br />
                    {order.order_status}
                  </p>
                </div>
                <br />
                <div
                  className="text-muted mb-1 p-2 bordered-div"
                  style={{ border: "1px solid red" }}
                  onClick={() => {
                    window.open(order.invoice)
                  }}
                >
                  INVOICE
                </div>
              </Col>

              <Col>
                <div className="margin-left-30">
                  <p className="text-muted mb-0">
                    Name
                    <br />
                    {order.prod_name}
                  </p>
                  <br />
                  <p className="text-muted mb-0">
                    Brand Name
                    <br />
                    {order.brand}
                  </p>
                  <br />
                  <p className="text-muted mb-0">
                    BrakeUp part number
                    <br />
                    {order.brakeup_part_no}
                  </p>
                  <br />
                  <p className="text-muted mb-0">
                    Product type
                    <br />
                    {order.type}
                  </p>
                  <br />
                  <p className="text-muted mb-0">
                    Product Category
                    <br />
                    {order.category}
                  </p>
                  <br />
                  <p className="text-muted mb-0">
                    Axle
                    <br />
                    {order.axle}
                  </p>
                  <br />

                  <p className="text-muted mb-0">
                    Seller Part Number
                    <br />
                    {order.seller_part_no}
                  </p>
                  <br />
                  <p className="text-muted mb-0">
                    OEM Number
                    <br />
                    {order.oem_part_no}
                  </p>
                </div>
              </Col>

              <Col>
                <div className="text-muted mb-1 p-2 bordered-div ">
                  <div>
                    <h4>Price Details</h4>
                    <br />
                    <p className="text-muted mb-0">MRP : {order.mrp}</p>
                    <p className="text-muted mb-0">
                      Selling Price:{order.selling_price}
                    </p>
                    {/* <p className="text-muted mb-0">Normal Price: {order.price}</p> */}
                    {/* <p className="text-muted mb-0">Last Price</p> */}
                    <p className="text-muted mb-0">GST: {order.order_gst}</p>
                    <p
                      className="text-muted mb-0"
                      onClick={() => setModal(true)}
                      style={{ cursor: "pointer" }}
                    >
                      Profit Calculator
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <div
              className="d-flex flex-wrap gap-2"
              style={{ marginBottom: "120px", float: "right" }}
            >
              <Button
                type="submit"
                color="primary"
                className="btn "
                onClick={() => replaceProduct()}
              >
                Replacement
              </Button>
            </div>
          </Container>
        )}
      </div>
    </React.Fragment>
  )
}

ProductDetails.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ProductDetails)
