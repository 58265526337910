import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  Button,
} from "reactstrap"
import classnames from "classnames"
import { isEmpty, map } from "lodash"

//Import Star Ratings
import StarRatings from "react-star-ratings"

// RangeSlider
import Nouislider from "nouislider-react"
import "nouislider/distribute/nouislider.css"

//Import Product Images
import { productImages } from "assets/images/product"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import data
import { discountData, productsData } from "common/data"

//Import actions
import { getProducts as onGetProducts } from "store/e-commerce/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { get } from "helpers/api_helper"
import Select from "react-select"

const ReplacementNewProductPage = props => {
  const dispatch = useDispatch()
  const { history } = props
  const [productList, setProductList] = useState([])
  const [activeTab, setActiveTab] = useState("1")
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(5)
  const [search, setSearch] = useState("")
  const [filter, setFilter] = useState([])

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  const [products, setProducts] = useState([])
  const getProducts = async () => {
    const result = await get(
      "/api/v2/products/product/?order=-1&&sort_by=created_at"
    )
    if (result.data) {
      setProductList(result.data)
    } else {
      setProductList([])
    }
  }
  useEffect(() => {
    getProducts()
  }, [])
  const handlePageClick = page => {
    setPage(page)
  }
  const onSearchChange = async (searchValue, filterValue) => {
    let url = "/api/v2/search/?q=product&order=-1&&sort_by=created_at"
    if (searchValue) {
      url = `${url}&s=${searchValue}`
    }
    if (filterValue) {
      let filter = ""
      filterValue &&
        filterValue.map(items => {
          if (items.value == "all") {
            filter = `${filter}&all=True`
          }
          if (items.value == "0") {
            filter = `${filter}&stock=0`
          }
          if (items.value == "low_stock") {
            filter = `${filter}&total_stock=5`
          }
          if (items.value == "in_active") {
            filter = `${filter}&inactive=False`
          }
          if (items.value == "active") {
            filter = `${filter}&active=True`
          }
          if (items.value == "new_product") {
            filter = `${filter}&new_products=new`
          }
        })
      url = `${url}${filter}`
    }
    setSearch(searchValue)
    setFilter(filterValue)

    const result = await get(url)
    // console.log(result,'resultresultresultresult')
    if (result) {
      setProductList(result)
    } else {
      setProductList([])
    }
  }
  const onSelectItem = async new_product => {
    // console.log(new_product, "pppppppppppp")
    const result = await get(
      `/api/v2/product/productdetail/?id=${new_product._id["$oid"]}`
    )

    if (result) {
      localStorage.setItem(
        "new_item",
        JSON.stringify({ ...new_product, price: result.MRP })
      )
      let order = localStorage.getItem("replace_item")
      if (order) {
        order = JSON.parse(order)
        history.push(`/orders/${order.order_id}/order-replacement-summary`)
      } else {
        history.push("/orders")
      }
    } else {
      history.push("/orders")
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Search New Products | BrakeUp</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title="Commercial"
            breadcrumbItem="Search New Products"
          />
          <Row>
            <Col lg="12">
              <Row className="mb-3">
                <Col xl="4" sm="6"></Col>
                <Col lg="8" sm="6">
                  <Form className="mt-4 mt-sm-0 float-sm-end d-flex align-items-center">
                    <div className="search-box me-2">
                      <div className="position-relative">
                        <Input
                          type="text"
                          className="form-control border-0"
                          placeholder="Search..."
                          onChange={e => onSearchChange(e.target.value, filter)}
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                    <div style={{ width: "300px" }}>
                      <Select
                        style={{ width: "200px" }}
                        classNamePrefix="select"
                        name="filers"
                        placeholder="Filters"
                        isMulti
                        options={[
                          { label: "All Products", value: "all" },
                          { label: "Zero Stock Products", value: "0" },
                          { label: "Low Stock", value: "low_stock" },
                          { label: "in Active Products", value: "in_active" },
                          { label: "Active Products", value: "active" },
                          {
                            label: "New Product Within Month",
                            value: "new_product",
                          },
                          {
                            label: "Frequently Ordered",
                            value: "frequently_ordered",
                          },
                        ]}
                        onChange={e => {
                          onSearchChange(search, e)
                        }}
                      />
                    </div>
                  </Form>
                </Col>
              </Row>
              <Row>
                {!isEmpty(productList) &&
                  productList.map((product, key) => (
                    <Col xl="4" sm="6" key={"_col_" + key}>
                      <Card
                        onClick={
                          () => onSelectItem(product)
                          //   history.push(
                          //     `/ecommerce-product-detail/${product._id["$oid"]}`
                          //   )
                        }
                      >
                        <CardBody>
                          <div className="product-img position-relative">
                            {/* {product.isOffer ? (
                              <div className="avatar-sm product-ribbon">
                                <span className="avatar-title rounded-circle bg-primary">
                                  {`-${product.offer}%`}
                                </span>
                              </div>
                            ) : null} */}

                            <img
                              src={
                                product.image[0].startsWith("https://")
                                  ? product.image[0]
                                  : `https://${product.image[0]}`
                              }
                              alt=""
                              className="img-fluid mx-auto d-block"
                            />
                          </div>
                          <div className="mt-4 text-center">
                            <span className="text-muted me-2">
                              Product Name
                            </span>
                            <h5 className="mb-3 text-truncate">
                              <Link
                                to={
                                  "/ecommerce-product-detail/" +
                                  product._id.$oid
                                }
                                className="text-dark"
                              >
                                {product.prod_name}
                              </Link>
                            </h5>
                            <span className="text-muted me-2">
                              Product Category
                            </span>
                            <h5 className="mb-3 text-truncate">
                              {product.category}
                            </h5>
                            <span className="text-muted me-2">Product ID</span>
                            <h5 className="mb-3 text-truncate">
                              {product.product_id}
                            </h5>
                            <span className="text-muted me-2">Brand</span>
                            <h5 className="mb-3 text-truncate">
                              {product.brand}
                            </h5>
                            <span className="text-muted me-2">Type</span>
                            <h5 className="mb-3 text-truncate">
                              {product.type}
                            </h5>
                            <span className="text-muted me-2">Stock</span>
                            <h5 className="mb-3 text-truncate">
                              {product.total_stock}
                            </h5>
                            <span className="text-muted me-2">
                              No of Sellers
                            </span>
                            <h5 className="mb-3 text-truncate">
                              {product.no_of_sellers}
                            </h5>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ReplacementNewProductPage.propTypes = {
  products: PropTypes.array,
  history: PropTypes.object,
  onGetProducts: PropTypes.func,
}

export default withRouter(ReplacementNewProductPage)
