import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Badge,
  DropdownItem,
  Row,
  Col,
  Table,
  Input,
  Form,
} from "reactstrap"
import companies from "assets/images/companies"

import { Link } from "react-router-dom"
import moment from "moment"

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"
import TableRow from "./buyerRow"
import { useHistory } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { get } from "../../helpers/api_helper"

//redux
import { useSelector, useDispatch } from "react-redux"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import { Loader } from "service/alerts"

const Buyer = props => {
  const [buyers, setBuyers] = useState([])
  const [limit, setLimit] = useState(40)
  const [last_id, setLastId] = useState("")
  const [search, setSearch] = useState("")
  const [totalBuyers, setTotalBuyers] = useState("")
  const [SlnoBuyers, setSlnoBuyers] = useState("")
  const [loader, setLoader] = useState(false)

  const history = useHistory()

  let total_buyers = ""

  const getBuyers = async () => {
    setLoader(true)

    get(`/api/v2/buyerdetails/?limit=${limit}`)
      .then(response => {
        setBuyers(response.data)
        setTotalBuyers(response.totalcount)
        setSlnoBuyers(response.totalcount)
        setLoader(false)
      })
      .catch(error => {
        setLoader(false)
      })
  }

  useEffect(() => {
    getBuyers()
  }, [])

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch])

  const onNext = () => {
    let last_item = buyers[buyers.length - 1]
    if (last_item && last_id != last_item._id.$oid) {
      get(
        `/api/v2/buyerdetails/?limit=${limit}&previousId=${last_item._id.$oid}`
      )
        .then(response => {
          if (response.data) {
            let new_buyers = [...buyers, ...response]
            setBuyers(new_buyers)
          }
        })
        .catch(error => {})
      setLastId(last_item._id.$oid)
    }
  }

  const onClickRow = user_id => {
    history.push(`/buyers/${user_id}`)
  }
  const onSearchChange = async (searchValue, filterValue) => {
    setLoader(true)

    let url = "/api/v2/search/?q=buyers"
    if (searchValue) {
      url = `${url}&s=${searchValue}`
    }
    setSearch(searchValue)
    try {
      const result = await get(url)
      if (result) {
        setBuyers(result?.data ?? [])
        setSlnoBuyers(result?.totalcount ?? "")
        setLoader(false)
      } else {
        setBuyers([])
        setLoader(false)
      }
    } catch {
      setBuyers([])
      setLoader(false)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content" style={{ background: "white" }}>
        <MetaTags>
          <title>Buyers | BrakeUp</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Buyers")}
            breadcrumbItem={props.t(`Buyers (${totalBuyers})`)}
          />
          <Row className="mb-3">
            <Col lg="7" sm="6">
              <div className="search-box me-2">
                <div className="position-relative">
                  <Input
                    type="text"
                    className="form-control border-0"
                    placeholder="Search Buyer by phone number, ID"
                    onChange={e => onSearchChange(e.target.value)}
                  />
                  <i className="bx bx-search-alt search-icon" />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            {loader && <Loader />}
            <PerfectScrollbar
              onYReachEnd={() => onNext()}
              style={{ height: "max-content" }}
            >
              <Col lg="12">
                <div className="">
                  <div className="table-responsive">
                    <Table className="project-list-table table-nowrap align-middle  table">
                      <tbody>
                        <Row>
                          {buyers.map((item, index) => {
                            // console.log(index,item.name, SlnoBuyers,"buyers.map")
                            return (
                              <>
                                <TableRow
                                  {...item}
                                  onClick={onClickRow}
                                  index={SlnoBuyers - index}
                                  color={item.is_Approved ? "green" : "red"}
                                  item={item}
                                />
                                <br />
                              </>
                            )
                          })}
                        </Row>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </PerfectScrollbar>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Buyer.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Buyer)
