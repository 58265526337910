import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Badge,
  DropdownItem,
  Row,
  Col,
  Table,
  Card,
} from "reactstrap"
import companies from "assets/images/companies"

import { Link } from "react-router-dom"
import moment from "moment"

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"

const BuyerRow = props => {
  // console.log(props, "buyerprops")

  const [modal, setmodal] = useState(false)
  const [subscribemodal, setSubscribemodal] = useState(false)

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
  }))

  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(true)
    }, 2000)
  }, [])

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch])

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y")
    return date1
  }

  const handleProjectClick = arg => {
    const project = arg

    setProjectList({
      id: project.id,
      img: project.img,
      name: project.name,
      description: project.description,
      status: project.status,
      color: project.color,
      dueDate: project.dueDate,
      team: project.team,
    })

    setIsEdit(true)

    toggle()
  }

  const handleDeleteProject = project => {
    dispatch(onDeleteProject(project))
  }
  return (
    <React.Fragment>
      <Col lg="4">
        <Card
          className={`mb-2`}
          onClick={() => props.onClick(props._id["$oid"])}
        >
          <Col lg="12">
            <div className="d-flex">
              <p className="text-muted me-2">{props.index}.</p>
              <div>
                <p className="text-muted mb-0">Name : {props.name}</p>
                <p className="text-muted mb-0">Contact No : {props.mobile}</p>
                <p className="text-muted mb-0">Email : {props.email}</p>
                <p className="text-muted mb-0">
                  {props["created_at"]
                    ? `Created at : ${moment(
                        props["created_at"]["$date"]
                      ).format("DD MMM Y, h:mm a")}`
                    : ""}
                </p>
                <p className="text-muted mb-0">
                  {props["last_purchased"]
                    ? `Last Purchase : ${moment(
                        props["last_purchased"]["$date"]
                      ).format("DD MMM Y, h:mm a")}`
                    : ""}
                </p>
              </div>
            </div>
          </Col>
        </Card>
      </Col>
    </React.Fragment>
  )
}

BuyerRow.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(BuyerRow)
