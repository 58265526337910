import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"

import * as echarts from "echarts"

const OrderStaticsChart = props => {
  const chartRef = useRef(null)

  useEffect(() => {
    // Initialize ECharts instance
    const chart = echarts.init(chartRef.current)

    const resizeChart = () => {
      chart.resize()
    }

    // ECharts configuration
    const options = {
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          return `${params.marker} ${params.name}: ${
            params.percent
          }%<br/> <p style="padding-left:20px; margin-bottom:0px " > Amount: ₹ ${
            params.value
          } </p>
          <p style="padding-left:20px " > Count: ${params.data.count || 0} </p>`
        },
      },
      legend: {
        top: "12%",
        right: "5%",
        orient: "vertical",
      },
      series: [
        {
          name: "Orders",
          type: "pie",
          center: ["40%", "50%"],
          radius: ["40%", "70%"],
          avoidLabelOverlap: true,
          itemStyle: {
            borderRadius: 10,
            borderColor: "#fff",
            borderWidth: 2,
          },
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 20,
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: false,
          },
          data: props.data,
          color: ["#2196F3", "#7055e6", "#FFD700", "#4CAF50", "#FF5733"],
        },
      ],
    }

    chart.setOption(options)
    window.addEventListener("resize", resizeChart)

    // Cleanup function
    return () => {
      chart.dispose()
      window.addEventListener("resize", resizeChart)
    }
  }, [props.data])

  return (
    <div
      ref={chartRef}
      style={{ height: "100%", width: "100%", minHeight: "300px" }}
    />
  )
}

export default withTranslation()(OrderStaticsChart)
