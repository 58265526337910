import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Badge,
  DropdownItem,
  Row,
  Col,
  Table

} from "reactstrap"
import companies from "assets/images/companies"

import { Link } from 'react-router-dom';
import moment from 'moment';

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"




//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import { get } from "../../helpers/api_helper";

const OrderRow = props => {
  const [modal, setmodal] = useState(false)
  const [pendingApprovals, setPendingApprovals] = useState([]);
  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData
  }))
  return (
    <React.Fragment>
      <tr className={`tr-border-${props.color} mb-2`}>
        <td >
          <div className="text-muted mb-1 p-2 bordered-div" >
            Brand Name
          </div>
        </td>
        <td >
          <div className="text-muted mb-1 p-2 bordered-div" >
            Action
          </div>
        </td>
      </tr>
      {props.pending && props.pending.map(items => {
        return (
          <tr className={`tr-border-${props.color} mb-2`}>
            <td >
              <div className="text-muted mb-1 p-2 bordered-div" >
                {items.brand_name}
              </div>
            </td>
            <td onClick={()=>props.onClick(items)}>
              <div className="text-muted mb-1 p-2 bordered-div" >
                Edit
              </div>
            </td>
          </tr>
        )
      })}

    </React.Fragment>
  )
}

OrderRow.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(OrderRow)
